<template>
    <f7-page 
        name="SupportList" 
        bg-color="white"
        ptr 
        :ptr-preloader="false" 
        @ptr:refresh="refrashPage"
        infinite
        :infinite-preloader="allowInfinite"
        @infinite="infinitePage"
        @page:reinit="aaaa">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-ios="material:menu" icon-md="material:menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title sliding>Поддержка</f7-nav-title>
            <f7-nav-right>
              <f7-link icon-only href="/cart/">
                <f7-icon f7="cart_fill" class="icon f7-icons">
                  <f7-badge v-if="$store.state.basket.count > 0" color="lightblue">{{ $store.state.basket.count }}</f7-badge>
                </f7-icon>
              </f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-toolbar bottom>
            <f7-link href="/support/add/">ДОБАВИТЬ ЗАПРОС</f7-link>
        </f7-toolbar>
        <!-- <f7-block> -->
            <f7-block-title v-show="!loading && !loadingGlobal" medium>Список запросов</f7-block-title>
            <f7-block-title v-show="loading || loadingGlobal" class="skeleton-text skeleton-effect-blink" medium>Loremipsum Loremipsum</f7-block-title>
            
            <f7-list v-if="items.length === 0" v-show="!loading && !loadingGlobal">
                <f7-list-item title="У Вас еще не было запросов"></f7-list-item>
            </f7-list>
            <f7-list v-if="items.length > 0" v-show="!loading && !loadingGlobal" media-list >
                <f7-list-item v-for="item in items" :link="`/support/${item.id}/`" :title="item.title" :subtitle="item.data" :key="item.id">
                    <f7-icon v-if="item.status === 'notanswered'" slot="media" f7="envelope" size="40px" color="yellow"></f7-icon>
                    <f7-icon v-if="item.status === 'unread'" slot="media" f7="envelope_badge" size="40px" color="lightblue"></f7-icon>
                    <f7-icon v-if="item.status === 'read'" slot="media" f7="envelope_open" size="40px" color="green"></f7-icon>
                </f7-list-item>
            </f7-list>

            
            <f7-list v-show="loading || loadingGlobal" media-list  class="skeleton-text skeleton-effect-blink">
                <f7-list-item v-for="n in 3" link="" title="Lorem ipsum dolor Lorem ipsum dolor" subtitle="Lorem ipsum" :key="n">
                    <f7-skeleton-block style="width: 40px; height: 40px;" slot="media"></f7-skeleton-block>
                </f7-list-item>
            </f7-list>
        <!-- </f7-block> -->

    </f7-page>
</template>

<script>
export default {
    name: 'SupportList',
    computed: {
        loadingGlobal () {
            return this.$store.state.loading
        },
        items () {
            return this.$store.state.support.tickets || []
        }
    },
    data () {
        return {
            loading: false,
            page: 1,
            filter: {},
            allowInfinite: false,
            showPreloader: false,
        }
    },
    methods: {
        async getData () {
            try {console.log('getDatagetDatagetDatagetDatagetDatagetDatagetData')
                await this.$store.dispatch('support/ticketList')
                this.loading = false 
            } catch (err) {
                this.$store.commit('setError', err)
                this.loading = false 
            }
        },
        async refrashPage (done) {
            this.loading = true
            done()
            this.$store.commit('support/setTickets', [])
            await this.getData() 
        },
        async infinitePage () {
            try {
                console.log('infinitePage', this.showPreloader, this.allowInfinite)
                if (this.allowInfinite) return
                if ((this.$store.state.support.tickets || []).length >= this.$store.state.support.countTickets) return
                this.allowInfinite = true
                ++this.page
                await this.$store.dispatch('support/ticketList', {page: this.page, filter: this.filter})
                this.showPreloader = false
                this.allowInfinite = false
                return true
            } catch (err) {
                this.showPreloader = false
                this.allowInfinite = false
                this.$store.commit('setError', err)
            }
        },
        async aaaa () {
            console.log('aaaaaaaaaaaaaaaaaaa')
            this.loading = true
            this.$store.commit('support/setTickets', [])
            await this.getData()
        }
    },
    async mounted () {
        console.log('mountedmountedmountedmountedmountedmounted')
        this.loading = true
        this.$store.commit('support/setTickets', [])
        await this.getData()
    }
}
</script>

<style scoped>
.toolbar-inner a{ text-align: center; width: 100%;}
.list {margin-bottom: 48px;}
.block-title{margin-top: 16px;}
</style>