<template>
    <f7-page 
      name="Payments" 
      bg-color="white" 
      ref="payments"
      ptr 
      :ptr-preloader="false" 
      @ptr:refresh="refrashPage"
      infinite
      :infinite-preloader="allowInfinite"
      @infinite="infinitePage"
      @page:reinit="reinitPage">
        <!-- Top Navbar -->
        <f7-navbar title="Мои выплаты" back-link="Back"></f7-navbar>

        <f7-toolbar :inner="false" top>
          <f7-row no-gap class="top-statistic">
            <f7-col><div>Выплачено</div><div class="top-statistic-bonus">{{ totalPayments }} Бонусов</div></f7-col>
            <f7-col><div>Доступно</div><div class="top-statistic-bonus">{{ availableBonuses }} Бонусов</div></f7-col>
          </f7-row>
        </f7-toolbar>

        <f7-fab position="center-bottom" slot="fixed" color="lightblue" @click="sheetOpened= true" text="Заказать выплату"></f7-fab>

        <f7-list v-show="loading" media-list class="skeleton-text skeleton-effect-blink">
            <f7-list-item v-for="n in 3" header="Loremipsum " after="0000 Lorem" title="000000 12.12.2020" :key="n"></f7-list-item>
        </f7-list>

        <f7-list v-if="!loading && items.length === 0">
          <f7-list-item title="У Вас еще не было выплат"></f7-list-item>
        </f7-list>
        <f7-list v-if="!loading && items.length > 0" media-list>
          <f7-list-item v-for="item in items" :title="`Заявка ${item.id} от ${item.data}`" :key="item.id">
            <div v-if="item.status === 'denied'" slot="header" class="text-color-red">отказано</div>
            <div v-if="item.status === 'success'" slot="header" class="text-color-green">одобрено</div>
            <div v-if="item.status === 'inwork'" slot="header" class="text-color-yellow">на рассмотрении</div>
            <div v-if="item.status === 'booked'" slot="header" class="text-color-lightblue">заказано</div>
            <div slot="after">{{ item.total }} Баллов</div>
          </f7-list-item>
        </f7-list>

        <f7-sheet
          style="height:auto; --f7-sheet-bg-color: #fff;"
          :opened="sheetOpened" 
          @sheet:closed="sheetOpened = false"
          swipe-to-close
          backdrop
        >
          <f7-page-content>
            <f7-block>
              <!-- <div class="list no-hairlines-md">
                <ul>
                  <li>
                    <div class="item-content item-input item-input-with-error-message item-input-invalid">
                      <div class="item-media">
                        <i class="icon f7-icons">bold</i>
                      </div>
                      <div class="item-inner">
                        <div class="item-title item-label">Введите сумму выплаты</div>
                        <div class="item-input-wrap">
                          <input type="number" class="input-invalid">
                          <div class="item-input-error-message">Пожалуйста, введите число.</div>
                          <span class="input-clear-button"></span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> -->






              <f7-list no-hairlines-md>
                <f7-list-input
                class="aaaa"
                  label="Введите сумму выплаты"
              
                  required
                  validate
                  pattern="[0-9]*"
                  clear-button
                  :error-message-force="!isValidPaymentSumm"
                  :onValidate="(isValid) => setInputValid(isValid)"
                  :value="paymentSumm"
                  @input="paymentSumm = $event.target.value"
                  error-message="Только цифры, пожалуйста!"
                >
                  <f7-icon slot="media" f7="bold"></f7-icon>
                </f7-list-input>
              </f7-list>
            </f7-block>
            <f7-block>
              <f7-button raised outline @click="addPayments" large>Отправить</f7-button>
            </f7-block>
          </f7-page-content>
        </f7-sheet>

    </f7-page>
</template>

<script>
  export default {
    name: "Payments",
    computed: {
      totalPayments () {
        return this.$store.state.bonus.totalPayments || 0
      },
      availableBonuses () {
        return this.$store.state.bonus.availableBonuses || 0
      }
    },
    data () {
      return {
        loading: false,
        items: [],
        totalItems: 0,
        order: 'id',
        by: 'asc',
        page: 1,
        allowInfinite: false,
        sheetOpened: false,
        paymentSumm: null,
        isValidPaymentSumm: true
      }
    },
    methods: {
      setInputValid(isValid) {
        this.isValidPaymentSumm = isValid
        console.log('setInputValid', isValid)
      },
      changeInput(event) {
        console.log(event.target.value)
        // this.paymentSumm = String(event.target.value).replace(/[^-0-9]/gim,'')
        return String(event.target.value).replace(/[^-0-9]/gim,'')
      },
      async getData () {
        try {
          const { items, totalItems } = await this.$store.dispatch('bonus/getList', {
            page: this.page, 
            order: this.order, 
            by: this.by
          })
          if ((items || []).length > 0) {
            this.items = [...this.items, ...items]
          }
          this.totalItems = totalItems || 0
        } catch (err) {
          this.$store.commit('setError', err)
        }  
      },
      async infinitePage () {
        if (this.allowInfinite) 
          return false

        if ((this.items || []).length >= this.totalItems)
          return false

        this.allowInfinite = true
        ++this.page
        await this.getData()
        this.allowInfinite = false
      },
      async refrashPage(done) {
        this.loading = true
        done()
        this.clearItems()
        await this.getData()
        this.loading = false
      },
      async reinitPage () {
        this.loading = true
        this.clearItems()
        await this.getData()
        this.loading = false
      },
      clearItems () {
        this.items = []
        this.page = 1
      },
      async addPayments () {
        try {
          if (this.paymentSumm === null) {
            this.isValidPaymentSumm = false
            return false
          }

          await this.$store.dispatch('bonus/addPayment', this.paymentSumm)
          this.$f7.ptr.refresh()
        } catch (err) {
          this.isValidPaymentSumm = false
          this.$store.commit('setError', err)
        }
      },
    },
    async mounted () {
      this.loading = true
      this.clearItems()
      await this.getData()
      this.loading = false
    }
  }
</script>

<style scoped>
  .toolbar-inner a{ text-align: center; width: 100%;}
  .subnavbar .subnavbar-title{ 
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding: 5px;
  }
  .fab-extended {width: 80%;}
  .top-statistic {font-size: 12px; line-height: 1em; text-align: center;}
  .top-statistic-bonus {line-height: 1.8em;}
</style>