
import ProductsPage from '../pages/products'
import ProductPage from '../pages/product'
import ActionsPage from '../pages/actions'
import ActionPage from '../pages/action'
import ApplicationsPage from '../pages/applications'
import ApplicationPage from '../pages/application'
import BonusInfoPage from '../pages/bonusinfo'
import ReferencePage from '../pages/bonus/reference'
import PaymentsPage from '../pages/bonus/payments'
import CartPage from '../pages/cart'

import Login from '../pages/login'


import SupportListPage from '../pages/support/list'
import TicketPage from '../pages/support/ticket'

import store from '@/store';

// import HomePage from '../pages/home.vue';

import NotFoundPage from '../pages/404.vue';
const userIsLoggedIn = false
const routes = [
  {
    path: '/',
    // component: ProductsPage,
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ProductsPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/product/:productId/',
    component: ProductPage,
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ProductsPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/actions/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ActionsPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/actions/:actionId/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ActionPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/applications/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ApplicationsPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/applications/:applicationId/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ApplicationPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/bonusinfo/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: BonusInfoPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/reference/:articleId/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: ReferencePage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/payments/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: PaymentsPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/support/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: SupportListPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/support/add/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: TicketPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/support/:ticketId/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: TicketPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },
  {
    path: '/cart/',
    async(routeTo, routeFrom, resolve, reject) {
      if (store.state.auth.isUserLoggedIn) {
        resolve({
          component: CartPage,
        });
      } else {
        resolve({
          component: Login
        });
      }
    }
  },


  // {
  //   path: '/about/',
  //   component: AboutPage,
  // },
  // {
  //   path: '/form/',
  //   component: FormPage,
  // },
  //
  //
  // {
  //   path: '/dynamic-route/blog/:blogId/post/:postId/',
  //   component: DynamicRoutePage,
  // },
  // {
  //   path: '/request-and-load/user/:userId/',
  //   async: function (routeTo, routeFrom, resolve, reject) {
  //     // Router instance
  //     var router = this;
  //
  //     // App instance
  //     var app = router.app;
  //
  //     // Show Preloader
  //     app.preloader.show();
  //
  //     // User ID from request
  //     var userId = routeTo.params.userId;
  //
  //     // Simulate Ajax Request
  //     setTimeout(function () {
  //       // We got user data from request
  //       var user = {
  //         firstName: 'Vladimir',
  //         lastName: 'Kharlampidi',
  //         about: 'Hello, i am creator of Framework7! Hope you like it!',
  //         links: [
  //           {
  //             title: 'Framework7 Website',
  //             url: 'http://framework7.io',
  //           },
  //           {
  //             title: 'Framework7 Forum',
  //             url: 'http://forum.framework7.io',
  //           },
  //         ]
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();
  //
  //       // Resolve route to load page
  //       resolve(
  //         {
  //           component: RequestAndLoad,
  //         },
  //         {
  //           context: {
  //             user: user,
  //           }
  //         }
  //       );
  //     }, 1000);
  //   },
  // },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
