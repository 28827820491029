<template>
    <f7-page name="Reference" bg-color="white">
        <!-- Top Navbar -->
        <f7-navbar :title="title" back-link="Back"></f7-navbar>

        <f7-block-title v-show="loading" class="skeleton-text skeleton-effect-blink">Loremipsum ipsumdolor</f7-block-title>
        <f7-block v-show="loading" strong class="skeleton-text skeleton-effect-blink">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies, est sed ultricies maximus, quam tellus maximus nibh, eu mollis ligula mi sed ex. Maecenas suscipit suscipit placerat. Maecenas id malesuada turpis, faucibus commodo ligula. In nec porttitor velit. Sed felis elit, maximus ac lacus id, pharetra accumsan nulla. Donec bibendum nunc velit, sit amet volutpat tellus rutrum sed. Suspendisse potenti. Vivamus luctus a ipsum vel tristique. Duis tempus malesuada erat. </p>
        </f7-block>

        <f7-block-title v-show="!loading">{{ title }}</f7-block-title>
        <f7-block v-show="!loading" strong>
            {{ text }}
        </f7-block>
    </f7-page>
</template>

<script>
  export default {
    name: "Reference",
    data () {
      return {
        loading: false,
        title: null,
        text: null
      }
    },
    methods: {
      async getData () {
        try {
          if (!this.$f7route.params.hasOwnProperty('articleId')) {
            throw new Error('data error')  
          }
          
          const { title, text } = await this.$store.dispatch('bonus/getCondition', this.$f7route.params.articleId)
          this.title = title || null
          this.text = text || null           
        } catch (err) {
          this.$store.commit('setError', err)
        }
      }  
    },
    async mounted () {
      this.loading = true
      await this.getData()
      this.loading = false 
    }
  }
</script>

<style scoped>

</style>