import api from '../js/api.js'

const state = {
  routeId: null,
  pointId: null,
  tasksId: null,
  documentId: null,
  points: [],
  tasks: [],
  documents: [],
  products: []
}

const getters = {}

const actions = {
  async getRoutes ({ commit }, filter) {
    try {
      const { data, status, errorMessage } = await api.get('/routes/', filter)
      if (!data || !status || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      } // payment terminal
      return data //.map(item => ({paymentTerminal: (item.routeNumber == '719' || item.routeNumber == '732' ? '1' : '0'), ...item}))
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  async getPoints ({commit, state}, routeId) {
    try {
      if (!routeId) {
        throw new Error('routeID is missing')
      }
      if (state.routeId != routeId || state.points.length === 0) {
        commit('setRouteId', routeId)
        const { data, status, errorMessage } = await api.get(`/routes/${routeId}`)
        if (!data || !status || String(errorMessage).length > '0') {
          throw errorMessage || new Error('uncorrected response')
        }
        commit('setPoints', data)
      }
      return true
    } catch (err) {
      commit('setPoints', [])
      throw err
    }
  },
  async getDocuments ({commit}, taskId) {
    try {
      if (!taskId) {
        throw new Error('taskID is missing')
      }
      if (state.taskId != taskId || state.tasks.length === 0) {
        commit('setTaskId', taskId)
        const {data, status, errorMessage} = await api.get(`/tasks/${taskId}`)
        if (!data || !status || String(errorMessage).length > '0') {
          throw errorMessage || new Error('uncorrected response')
        }
        console.log('setDocuments', data)
        commit('setDocuments', data)
      }
      return true
    } catch (err) {
      throw err
    }
  },
  async getTasks  ({commit}, pointId) {
    try {
      if (!pointId) {
        throw new Error('pointID is missing')
      }
      if (state.pointId != pointId || state.points.length === 0) {
        commit('setPointId', pointId)
        const {data, status, errorMessage} = await api.get(`/points/${pointId}`)
        if (!data || !status || String(errorMessage).length > '0') {
          throw errorMessage || new Error('uncorrected response')
        }
        console.log('setTasks', data)
        commit('setTasks', data)
      }
      return true
    } catch (err) {
      throw err
    }
  },
  async getProducts ({commit}, documentId) {
    try {
      if (!documentId) {
        throw new Error('documentID is missing')
      }
      commit('setDocumentId', documentId)
      const { data, status, errorMessage } = await api.get(`/documents/${documentId}`)
      if (!data || !status || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }
      commit('setProducts', data)
      return true
    } catch (err) {
      throw err
    }
  },
  async closePointServer ({commit, dispatch, state}, pointId) {
    try {
      // эмулируем моковые данные с закрытой точкой
      // const { data, status, errorMessage } = await (() => new Promise(resolve => {
      //   setTimeout(() => {
      //
      //     const points = state.points.map(point => String(point.pointsId) === String(pointId) ? ({...point, pointsPointStatus: 'close'}) : point)
      //     resolve({
      //       status: '1',
      //       data: points,
      //       errorCode: '',
      //       errorMessage: ''
      //     })
      //   }, 700)
      // }))()

      const { data, status, errorMessage } = await api.post('/point/close/', {pointsId: pointId})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      commit('setClearPoints')
      await dispatch('getPoints', state.routeId)
      return true
    } catch (err) {
      throw err
    }
  },
  async closeDocumentsServer ({commit, dispatch, state}, { documentId, productsDB, geo, documentCash }) {
    try {
      if ((productsDB || []).length === 0) {
        productsDB = ['']
      }
      if (!geo) {
        geo = ''
      }
      if (!documentCash) {
        documentCash = ''
      }
      const { data, status, errorMessage } = await api.post('/document/save/', { documentId, geo, documentCash, productsDB })
      console.log('closeDocumentsServer1', data, status, errorMessage)
      console.log('closeDocumentsServer2', !data, !status, String(status) !== '1', String(errorMessage).length > '0')
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      if ((state.documents || []).length === 1) {
        commit('setClearPoints')
        await dispatch('getPoints', state.routeId)
      } else {
        commit('setClearDocument')
        await dispatch('getDocuments', state.pointId)
      }
      return true
    } catch (err) {
      throw err
    }
  }
}

// mutations
const mutations = {
  setRouteId (state, routeId) {
    state.routeId = routeId
  },
  setPointId (state, pointId) {
    state.pointId = pointId
  },
  setTaskId (state, taskId) {
    state.taskId = taskId
  },
  setDocumentId (state, documentId) {
    state.documentId = documentId
  },
  setPoints (state, points) {
    state.points = points
  },
  setTasks (state, tasks) {
    state.tasks = tasks
  },
  setDocuments (state, documents) {
    state.documents = documents
  },
  setProducts (state, products) {
    state.products = products
  },
  setClearData (state) {
    state.routeId = null
    state.pointId = null
    state.tasksId = null
    state.documentId = null
    state.points = []
    state.tasks = []
    state.documents = []
    state.products = []
  },
  setClearPoints (state) {
    state.pointId = null
    state.tasksId = null
    state.documentId = null
    state.points = []
    state.tasks = []
    state.documents = []
    state.products = []
  },
  setClearTasks (state) {
    state.tasksId = null
    state.documentId = null
    state.tasks = []
    state.documents = []
    state.products = []
  },
  setClearDocument (state) {
    state.documentId = null
    state.documents = []
    state.products = []
  },
  setClearProducts (state) {
    state.products = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
