import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import courier from './courier'
import product from './product'
import actions from './actions'
import sales from './sales'
import support from './support'
import bonus from './bonus'
import applications from './applications'
import basket from './basket'

import base from './base'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState()
  ],
  modules: {
    auth,
    courier,
    product,
    actions,
    sales,
    support,
    bonus,
    applications,
    basket,

    base
  },
  state: {
    deviceId: null,
    loading: false,
    noConnection: false,
    error: null,
    message: null
  },
  actions: {
    setLoading ({commit}, loading) {
      commit('setLoading', loading)
    },
    setNoConnection ({commit}, value) {
      console.log('store-setNoConnection', value)
      commit('setNoConnection', value)
    },
    setDeviceId ({commit, state}) {
      if(!state.deviceId) {
        let deviceId = ''
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        for (let i = 0; i < 12; i++) {
          deviceId += possible.charAt(Math.floor(Math.random() * possible.length))
        }
        commit('setDeviceId', deviceId)
      }
    },
    async setStartData ({commit, dispatch}) {
      try {
        commit('setLoading', true)
        await Promise.all([

          // dispatch('product/productList'),
          // dispatch('actions/actionsList')
          // Данные авторизации
          //количество товаров в карзине
          dispatch('basket/chekCount'),
          // Бонусы (Доступные / на проверке)
          dispatch('bonus/getBalans')
          // Новые сообщения
          //Новые статусы в запросах
        ])
        commit('setLoading', false)
      } catch (err) {
        throw err
      }
    }
  },
  mutations: {
    setLoading (state, loading) {
      state.loading = loading
    },
    setNoConnection (state, value) {
      state.noConnection = value
    },
    setDeviceId (state, deviceId) {
      state.deviceId = deviceId
    },
    setError (state, error) {
      state.error = error
    },
    setMessage (state, message) {
      state.message = message
    }
  },
  getters: {
    noConnection: state => state.noConnection,
    error: state => state.error,
    message: state => state.message
  }
})
