<template>
    <f7-page name="Cart" class="page-cart" bg-color="white" ptr :ptr-preloader="false" @ptr:refresh="refrashPage">
      <!-- Top Navbar -->
      <f7-navbar>
          <f7-nav-left>
              <f7-link icon-ios="material:menu" icon-md="material:menu" panel-open="left"></f7-link>
          </f7-nav-left>
          <f7-nav-title sliding>Оформи продажу</f7-nav-title>
        <f7-nav-right>
          <f7-link icon-ios="f7:checkmark_circle" icon-md="f7:checkmark_circle" @click="checkedAll"></f7-link>
          <f7-link icon-ios="f7:trash" icon-md="f7:trash" @click="checkedDelete"></f7-link>
        </f7-nav-right>

        <f7-subnavbar>
          <f7-segmented >
            <div class="row" style="width: 100%;">
              <div class="col-50" style="width: 50%; padding: 0 10px; font-weight: 700;">ВСЕГО</div>
              <div class="col-50" style="width: 50%; padding: 0 10px; text-align: right; font-weight: 700;">{{ total }} Баллов</div>
            </div>
          </f7-segmented>
        </f7-subnavbar>
      </f7-navbar>

      <f7-fab v-show="!file && items.length > 0" position="center-bottom" slot="fixed" color="lightblue" @click="addReceipt" text="ДОБАВИТЬ ЧЕК ДЛЯ ПОДТВЕРЖДЕНИЯ" style="width: 80%; text-align: center;"></f7-fab>

      <f7-list v-show="loading" media-list class="skeleton-text skeleton-effect-blink">
        <f7-list-item v-for="n in 3" :key="n" header="Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor" after="50 Lorem">
          <f7-skeleton-block style="width: 80px; height: 60px;" slot="media"></f7-skeleton-block>
        </f7-list-item>
      </f7-list>

      <f7-block-title v-show="!loading && items.length === 0" medium style="text-align: center;">Товары отсутствуют</f7-block-title>
      <button v-if="!loading && items.length === 0" class="button button-fill button-round color-lightblue button-large" style="width: 80%; margin: 0 auto; height: var(--f7-fab-extended-size);" @click="$f7router.navigate('/')">Перейти в каталог</button>

      <f7-list v-show="!loading && items.length > 0" media-list class="search-list searchbar-found">
        <f7-list-item 
          v-for="item in items" 
          :key="item.basketId" 
          :header="item.title"
          :swipeout="item.productId !== null"
        >
          <f7-swipeout-actions v-if="item.productId" right>
            <f7-swipeout-button color="red" @click="onDeleted(item.basketId)">Удалить</f7-swipeout-button>
          </f7-swipeout-actions>
          <div slot="after" class="text-color-primary">{{ item.total }} Баллов</div>
          <img v-if="item.productId" slot="media" :data-src="item.src" class="lazy lazy-fade-in" width="80" height="60" />
          <img v-else slot="media" :src="item.src" width="80" height="60" />

          <div v-show="item.productId" slot="title" class="stepper stepper-round stepper-fill stepper-small color-lightblue">
            <div class="stepper-button-minus" @click="minusclick(item.basketId)"></div>
            <div class="stepper-value">{{ item.quantity }}</div>
            <div class="stepper-button-plus" @click="plusclick(item.basketId)"></div>
          </div>



          <!-- <f7-stepper v-show="item.productId" slot="title" small round fill color="lightblue" :input="false" :value="item.quantity" @input="quantityEdit(item.basketId, $event)"></f7-stepper> -->
          <div slot="content-start"><f7-checkbox :disabled="!item.productId" :checked="item.checked" @change="checkedItem(item.basketId, $event.target.checked)"></f7-checkbox></div>
        </f7-list-item>


        <f7-card v-if="file" no-shadow>
          <f7-card-content>
            <div class="card-images">
              <img v-show="!loading" :src="`${backgroundImage}`" />
            </div>
            <f7-link v-show="!loading" style="position: absolute; top: 20px; right: 20px;" icon-ios="f7:trash" icon-md="f7:trash" @click="fileDelete"></f7-link>
            <f7-skeleton-block v-show="loading" style="width: 250px; height: 190px;margin: 0 auto 15px"></f7-skeleton-block>
          </f7-card-content>
        </f7-card>

        <input id="input-file-cart" type="file" @change="handleFileChange" style="display: none;">

        <button v-if="backgroundImage && items.length > 0" class="button  button-fill button-round color-lightblue button-large" style="width: 80%; margin: 0 auto; height: var(--f7-fab-extended-size);" @click="createApplication">Отправить</button>
        

    </f7-list>
    </f7-page>
</template>

<script>
  // const FileReader = require('filereader')

  export default {
    name: "Cart",
    computed: {
      total () {
        return this.$store.state.basket.total
      },
      items () {
        return this.$store.state.basket.items || []
      },
      file () {
        return this.$store.state.basket.file || null
      },
      backgroundImage () {
        return this.$store.state.basket.backgroundImage || null
      }
    },
    data () {
      return {
        loading: false,
      }
    },
    methods: {
      async refrashPage (done) {
          this.loading = true
          done()
          this.$store.commit('product/setProducts', [])
          await this.getData() 
      },
      async getData () {
        await this.$store.dispatch('basket/getList')
        this.$f7.lazy.create('.page-cart.page-current')
        this.loading = false
      },
      async checkedAll () {
        try {
          if(this.items.length === 0) return false

          const checked = this.items.filter(x => x.checked === true).length !== this.items.length ? true : false
          await this.$store.dispatch('basket/checkedItem', this.items.map(x => ({basketId: x.basketId, checked})))
          return true
        } catch (error) {
          this.$store.commit('setError', err)
        }
      },
      async checkedDelete () {
        try {
          if(this.items.filter(x => x.checked === true).length === 0) return false
          
          const answer = await (() => new Promise(resolve => {
            this.$f7.dialog.confirm('Вы уверены, что хотите удалить все выбранные эллементы?', 'Удаление элементов', 
            () => {
              resolve(true)
            },
            () => {
              resolve(false)
            })
          }))()

          if (answer) {
            this.loading = true
            await this.$store.dispatch('basket/deleteElement', this.items.filter(x => x.checked === true).map(x => x.basketId))
          }
          this.loading = false
          return true
        } catch (error) {
          this.$store.commit('setError', err)
          this.loading = false
        }
      },
      async checkedItem (basketId, checked) {
        try {
          await this.$store.dispatch('basket/checkedItem', [{ basketId, checked }])
          return true
        } catch (error) {
          this.$store.commit('setError', err)
        }
      },
      async minusclick (basketId) {
        try {
          await this.$store.dispatch('basket/updateItems', { basketId, quantity: -1 })
        } catch (error) {
          this.$store.commit('setError', err)
        }
      },
      async plusclick (basketId) {
        try {
          await this.$store.dispatch('basket/updateItems', { basketId, quantity: 1 })
        } catch (error) {
          this.$store.commit('setError', err)
        }
      },
      async onDeleted(basketId) {
        try {
          await this.$store.dispatch('basket/deleteElement', [basketId])
        } catch (error) {
          this.$store.commit('setError', err)
        }
      },
      addReceipt () {
        const input = document.getElementById('input-file-cart')
        input.click()
      },
      handleFileChange (e) {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = readerEvent => {
          this.$store.commit('basket/setFile', file)
          this.$store.commit('basket/setBackgroundImage', readerEvent.target.result)
        }
      },
      fileDelete () {
        this.$store.commit('basket/setFile', null)
        this.$store.commit('basket/setBackgroundImage', null)
      },
      async quantityEdit (basketId, quantity) {
        await this.$store.dispatch('basket/updateItems', { basketId, quantity })
      },
      async createApplication () {
        try {
          this.loading = true
          const applicationId = await this.$store.dispatch('basket/setApplication')
          this.loading = false
          // тут надо выдать окно с тем что заказ оформлен и перейти в заказы, в каталог или закрыть окно
          this.$f7.dialog.alert(`Оформлен заказ ${applicationId}. Ожидайте проверки в течении 1-3 рабочих дня`)
        } catch (error) {
          this.$store.commit('setError', err)
          this.loading = false
        }
      }
    },
    async mounted () {
      this.loading = true
      await this.getData()
    }
  }
</script>

<style scoped>
  .page-cart .stepper-small {--f7-stepper-height: 20px;}
  .page-cart .item-title {margin-top: 5px;}
  .page-cart .list .item-media {margin-left: 5px;}
  .page-cart .stepper-small .stepper-button-minus {width: 30px !important;}
  .page-cart .stepper-small .stepper-button-plus {width: 30px !important;}
  .page-cart .stepper .stepper-input-wrap input {width: 30px;}

  .demo-card-header-pic .card-header {
      height: 40vw;
      background-size: cover;
      background-position: center;
      color: #fff;
  }

  .card-images {max-width: 250px;text-align: center;margin: 15px auto; position: relative;}
  .card-images img { width: 100%; height: auto}
</style>