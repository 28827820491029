<template>
    <f7-page name="products" bg-color="white">
        <!-- Top Navbar -->
        <f7-navbar :title="product.title" back-link="Back">
          <f7-nav-right>
            <f7-link icon-only href="/cart/">
              <f7-icon f7="cart_fill" class="icon f7-icons">
                <f7-badge v-if="$store.state.basket.count > 0" color="lightblue">{{ $store.state.basket.count }}</f7-badge>
              </f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-fab position="center-bottom" slot="fixed" color="lightblue" @click="addCart" text="ДОБАВИТЬ ПРОДАЖУ"></f7-fab>

        <f7-card v-show="loading" class="skeleton-text skeleton-effect-blink" no-shadow>
          <f7-card-header class="no-border">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry
          </f7-card-header>
          <f7-card-content :padding="false" style="padding: 0 15px;">
              <f7-row no-gap>
                <f7-col width="25">
                  <div class="card-images">
                    <f7-skeleton-block style="height: 5em;"></f7-skeleton-block>
                  </div>
                </f7-col>
                <f7-col width="75">
                  <f7-list>
                    <f7-list-item title="brandbrand" after="product-brand"></f7-list-item>
                    <f7-list-item title="articularticul" after="product-articule"></f7-list-item>
                  </f7-list>
                </f7-col>
              </f7-row>
          </f7-card-content>
        </f7-card>

        <f7-card v-show="!loading" no-shadow style="padding-bottom: 56px;">
            <f7-card-header class="no-border">
                {{ product.title }}
            </f7-card-header>
            <f7-card-content :padding="false" >
              <f7-row no-gap>
                <f7-col width="25">
                  <div class="card-images">
                    <img :src="product.src" />
                  </div>
                </f7-col>
                <f7-col width="75">
                  <f7-list>
                    <f7-list-item title="Бренд" :after="product.brand">
                    </f7-list-item>
                    <f7-list-item title="Артикул" :after="product.articule">
                    </f7-list-item>
                  </f7-list>
                </f7-col>
              </f7-row>

                <f7-block-title medium class="text-color-primary" style="margin-top: 16px; margin-bottom: 0; font-size: 20px;">Вы можете получить:</f7-block-title>

                <f7-list accordion-list>
                  <f7-list-item v-for="action in product.actions" :key="action.id" accordion-item :title="action.title" footer="Подробнее">
                    <span slot="after" class="text-color-primary">{{ action.bonus }} Баллов</span>
                    <f7-accordion-content>
                      <!-- <f7-card no-shadow style="margin: 16px;"> -->
                        <!-- <f7-card-content class="no-border" :padding="false" > -->
                          <div style="padding: 15px;">
                            <f7-block-title style="margin: 0; font-size: 16px">{{ action.title }}</f7-block-title>
                            <p>{{ action.description }}</p>
                            <f7-block-title v-if="action.actionType ==='quantity'" style="margin-left: 0;">Статистика</f7-block-title>
                            <f7-list v-if="action.actionType ==='quantity'">
                              <f7-list-item title="Сумма бонусов за шаг">
                                <span slot="after" class="text-color-primary">150 Баллов</span>
                              </f7-list-item>
                              <f7-list-item title="Шаг акции (шт)" badge="10"></f7-list-item>
                              <f7-list-item title="Набрано (шт)" badge="3" badge-color="lightblue"></f7-list-item>
                              <f7-list-item title="На рассмотрении (шт)" badge="0" badge-color="yellow"></f7-list-item>
                              <f7-list-item title="Необходимо (шт)" badge="7" badge-color="red"></f7-list-item>
                            </f7-list>
                          </div>

                        <!-- </f7-card-content> -->
                      <!-- </f7-card> -->
                    </f7-accordion-content>
                  </f7-list-item>
                </f7-list>
            </f7-card-content>
            <!-- <f7-card-footer class="no-border"> <div class="content-bonus-footer">Вы получите <span class="text-color-primary">{{product.bonus}} баллов</span></div></f7-card-footer> -->
        </f7-card>
        
        <!-- <f7-list accordion-list>
            <f7-list-item v-if="action.hasOwnProperty('title')" accordion-item title="Информация о акции">
                <f7-accordion-content>
                    <f7-block>
                        <f7-block-title>{{ action.title }}</f7-block-title>
                        <p>{{ action.description }}</p>
                    </f7-block>
                </f7-accordion-content>
            </f7-list-item>
            <f7-list-item v-if="actionProducts.length > 0" accordion-item title="Товары в текущей акции">
                <f7-accordion-content>
                    <f7-list media-list>
                        <f7-list-item v-for="actionProduct in actionProducts" :key="actionProduct.productId" :link="`/product/${actionProduct.productId}/`" :header="actionProduct.title">
                            <div slot="after" class="text-color-primary">{{ actionProduct.bonus }} Баллов</div>
                            <img slot="media" :src="actionProduct.src" width="80" />
                        </f7-list-item>
                    </f7-list>
                </f7-accordion-content>
            </f7-list-item>
        </f7-list> -->

        <f7-sheet
          style="height:auto; --f7-sheet-bg-color: #fff;"
          :opened="sheetOpened" @sheet:closed="sheetOpened = false"
          backdrop
        >
          <f7-page-content>
            <f7-block>
              <p style="text-align: center;font-size: 20px;">Прордолжить набор или подтвердить продажу?</p>
            </f7-block>
            <f7-block>
              <f7-row tag="p">
                <f7-col tag="span">
                  <f7-button raised fill @click="goCart">В корзину</f7-button>
                </f7-col>
                <f7-col tag="span">
                  <f7-button raised outline @click="goProduct">В каталог</f7-button>
                </f7-col>
              </f7-row>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
        
    </f7-page>
</template>

<script>
  export default {
    name: "product",
    computed: {
      action () {
        if ((this.$store.state.actions.actions || []).length > 0 && Object.keys(this.product || {}).length > 0 && this.product.hasOwnProperty('actionId')) {
          let action = this.$store.state.actions.actions.find(x => parseInt(x.actionId, 10) === parseInt(this.product.actionId, 10))
          if (action) {
            return action
          }
        }
        return {}
      },
      actionProducts () {
        if ((this.$store.state.product.products || []).length > 0 && Object.keys(this.product || {}).length > 0 && this.product.hasOwnProperty('actionId')) {
          return this.$store.state.product.products.filter(item => {
            if (parseInt(item.productId, 10) !== parseInt(this.$f7route.params.productId, 10) && parseInt(item.actionId, 10) === parseInt(this.product.actionId, 10)) {
              return true
            }
            return false
          })
        }
        return []
      }
    },
    data () {
      return {
        loading: false,
        sheetOpened: false,
        title: null,
        src: null,
        brand: null,
        articule: null,
        actions: [],

        product: {}

      }
    },
    methods: {
      async addCart () {
        try {
          this.loading = true
          await this.$store.dispatch('basket/addElement', this.$f7route.params.productId)
          this.loading = false
        } catch (err) {
          this.$store.commit('setError', err)
          this.loading = false
        }
        // const action = await (() => new Promise((resolve) => {
        //   this.$f7.dialog.confirm('Прордолжить набор или подтвердить продажу?', () => {
        //     resolve(true)
        //   }, {buttonOk: 'dsfsdfsdf'})
        // }))()
        this.sheetOpened = true
      },
      goCart () {
        this.sheetOpened = false
        this.$f7router.navigate('/cart/')
      },
      goProduct () {
        this.sheetOpened = false
        this.$f7router.navigate('/')
      },
      async getData () {
        try {
          if (!this.$f7route.params.hasOwnProperty('productId')) {
            throw new Error('data error')  
          }
          const { product } = await this.$store.dispatch('product/getProduct', this.$f7route.params.productId)
          this.product = product
          this.loading = false

        } catch (error) {
          
        }
      }
    },
    async mounted () {
      this.loading = true
      await this.getData()
    }
  }
</script>

<style scoped>
  .card-images {text-align: center;margin-top: 15px}
  .card-images img {max-width: 250px; width: 100%; height: auto}
  .content-bonus-footer {text-align: center; width: 100%}
  .toolbar-inner a{ text-align: center; width: 100%;}
  .fab-extended {width: 80%;}
</style>
