import api from '../js/api.js'

const state = {
  filterProductType: [],
  filterAction: [],
  filterBrand: [],
  productTypes: [],
  actions: [],
  brands: [],
  items: [],
  count: [],
}

const getters = {}

const actions = {
  async productList ({ state, rootState, commit }, params = {}) {
    try {
      let { page, rows, order, by, filter, search, barcode } = params
      page = page && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1
      rows = rows && !isNaN(parseInt(rows, 10)) ? parseInt(rows, 10) : 10
      search =  search || null
      order = order ? order : 'title'
      by = by ? by : 'desc'
      filter = filter ? filter : {}
      barcode = barcode || null

      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const items = []
          let products = []
          if (barcode === null) {
            filter = filterArrayValues(filter)
            // собираем номенклатуру
            products = filterProductsProperty(filter, [...rootState.base.productProperty], [...rootState.base.products])
  
            // пропускаем через поиск
            if (search) {
              products = searchProducts(search, products)
            }
          } else {
            products = [...rootState.base.products].filter(x => String(x.barcode) === String(barcode))
          }


          // сортируем
          products.sort((a, b) => {
            let propA = String(order !== 'bonus' ? a[order] : a.actions.length === 1 ? a.actions[0].bonus : a.actions[0].hasOwnProperty('quantity') ? a.actions[1].bonus : a.actions[0].bonus)
            let propB = String(order !== 'bonus' ? b[order] : b.actions.length === 1 ? b.actions[0].bonus : b.actions[0].hasOwnProperty('quantity') ? b.actions[1].bonus : b.actions[0].bonus)
            if ((by !== 'asc' && propA > propB) || (by === 'asc' && propA < propB)) {
              return 1
            }
            if ((by !== 'asc' && propA < propB) || (by === 'asc' && propA > propB)) {
              return -1
            }
            return 0
          })

          const start = (page - 1) * rows
          const end = rows * page < products.length ? rows * page : products.length

          for (let i = start; i < end; i++) {
            items.push(products[i])
          }
          const data = {
            items,
            count: products.length,
          }
          resolve({
            status: '1',
            data,
            errorCode: '',
            errorMessage: ''
          })
        }, 1000)
      }))()

      // const { data, status, errorMessage } = await api.post('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      const { items, count } = data
      if (page === 1) {
        commit('setProducts', [])
        commit('setProductCount', !isNaN(parseInt(count)) ? parseInt(count) : 0)
      }
      if (items && Array.isArray(items) && items.length > 0) {
        commit('setProducts', items)
      }
      
      return true
    } catch (err) {
      throw err
    }
  },

  async getProduct ({rootState, commit}, productId) {
    try {
      const { data, status, errorMessage } = await (() => new Promise((resolve) => {
        setTimeout(() => {
          let data = {product: {}, actionProducts: []}
          let actionProducts = []
          let products = [...rootState.base.products]
          const product = products.find(x => parseInt(x.productId, 10) === parseInt(productId, 10))
          
          if (product) {
            const actions = [] 
            if ((rootState.base.actions || []).length > 0) {
              product.actions.forEach(action => {
                const searchAction = rootState.base.actions.find(x => parseInt(action.id, 10) === parseInt(x.actionId, 10))
                if (searchAction) {
                  actions.push({ ...action, ...searchAction })
                } else {
                  actions.push({ ...action })
                }
              })
            }
            data = {
              product: {...product, actions },
              actionProducts,
            }
          }

          resolve({
              status: !product ? '0' : '1',
              data,
              errorCode: '',
              errorMessage: ''  
          })
        }, 1000)
      }))()
          // const { data, status, errorMessage } = await api.get('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }
      
      return data
    } catch (err) {
      throw err
    }
  },

  async getFilter ({ rootState }, filter = {}) {
    try {
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          filter = filterArrayValues(filter)
          let properties = [...rootState.base.productProperty]
          let products = filterProductsProperty(filter, properties, [...rootState.base.products])
          let items = propertyListFilter(filter, properties, products)
          let productQuantity = products.length

          const data = {
            items: [...items],
            productQuantity
          }
          resolve({
            status: '1',
            data,
            errorCode: '',
            errorMessage: ''
          })
        }, 1000)
      }))()

      // const { data, status, errorMessage } = await api.post('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      return data
    } catch (err) {
      throw err
    }
  },
}

const mutations = {
  setActions (state, actions) {
    state.actions = actions
  },
  setBrands (state, brands) {
    state.brands = brands
  },
  setProducts (state, items) {
    if (items && Array.isArray(items) && items.length > 0) {
      state.items = [...state.items, ...items]
    } else {
      state.items = []
      state.count = 0
    }
  },
  setProductCount (state, count) {
    state.count = count
  },
  setProductTypes (state, productTypes) {
    state.productTypes = productTypes
  },
}

function filterArrayValues(filters) {
  const filterArray = {}
  Object.keys(filters).forEach(key => {
    filterArray[key] = filters[key] !== null ? (typeof filters[key] === "string") ? [filters[key]] : filters[key] : []
  })
  return filterArray
}

function maxIndexFilterKeySelect(filters) {
  console.log(filters)
  if ((Object.keys(filters) || []).length === 0) {
    return 0
  }

  let ind = 0;
  (Object.keys(filters) || []).forEach((key, i) => {
    if (filters[key].length > 0) {
      ind = i
    }
  })
  return ind
}

function searchProducts(search, products) {
  
  let result = []
  result = products.filter(item => {
    if (item.title.toUpperCase().indexOf(search.toUpperCase()) !== -1 || item.barcode.toUpperCase().indexOf(search.toUpperCase()) !== -1 || item.articule.toUpperCase().indexOf(search.toUpperCase()) !== -1 || item.code.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
      return true
    }
    return false
  })
  return result
}

function filterProductsProperty (filter = {}, properties = [], products = []) {
  if ((Object.keys(filter)).length === 0 || (properties || []).length === 0) {
    return products
  }

  properties.forEach(prop => {
    if (filter.hasOwnProperty(prop.alias)) {
      if (products.length > 0) {
        products = products.filter(product => {
          if (!product.hasOwnProperty(prop.alias)) {
            return false
          } 
          console.log('filter[prop.alias] === null || filter[prop.alias].length === 0', prop.alias, filter[prop.alias] === null || filter[prop.alias].length === 0)
          if (filter[prop.alias] === null || filter[prop.alias].length === 0) {
            return true
          }

          let chek = false
          filter[prop.alias].forEach(value => {
            if (prop.alias === 'actions') {
              const sPropVal = (product.actions || []).find(x => String(x.id) === String(value))
              if (sPropVal) {
                chek = true
              }
            } else {
              if (String(product[prop.alias]) === String(value)) {
                chek = true
              }
            }
          })
          return chek
        })
      }
    }
  })
  return products
}

function propertyListFilter (filter = {}, properties = [], products = []) {
  if ((properties || []).length === 0) {
    return []
  }
// console.log('propertyListFilter-filter', filter)
  if ((Object.keys(filter)).length === 0) {
    return [...properties]
  }

  const maxIndexSelect = maxIndexFilterKeySelect(filter)
  properties = properties.map((item, i) => {
    let productValues = []
    products.forEach(product => {
      if (item.alias === 'actions') {
        products.forEach(product => {
          product.actions.forEach(action => {
            const s = productValues.find(x => String(x) === String(action.id))
            if (!s) {
              productValues.push(action.id)
            }
          })
        })
      } else {
        const s = productValues.find(x => String(x) === String(product[item.alias]))
        if (!s) {
          productValues.push(product[item.alias])
        }
      }

    })
    // console.log('filter[item.alias]filter[item.alias]filter[item.alias]filter[item.alias]',item.alias, filter[item.alias], productValues)
    const activeValues = [...filter[item.alias], ...productValues]
// console.log('activeValuesactiveValuesactiveValues', activeValues, item.values)
    const itemValues = item.values.map(val => {
      if (i === maxIndexSelect) {console.log(item.alias)
        return {...val, disabled: false }
      }
      const sVal = activeValues.find(x => String(x) === String(val.id))
      console.log('activeValuesactiveValuesactiveValues', activeValues, val.id, !sVal)
      return {...val, disabled: !sVal }
    })
    return {...item, values: itemValues}
  })
  return [...properties]
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
