<template>
  <f7-page bg-color="white">
    <f7-navbar title="Not found" back-link="Back"></f7-navbar>
    <f7-block strong>
      <p>Извините</p>
      <p>Запрошенные данные не найдены.</p>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {};
</script>
