import api from '../js/api.js'

const state = {
  tickets: [],
  countTickets: 0,
  ticketId: null,
  ticketTitle: null,
  ticketType: null,
  ticketApplicationNumber: null,
  messages: [],

  ticketsFake: [
    { id: 149, title: 'Запрос 346 от 27.09.2020', type: 'application', number: 346, status: 'notanswered', data: '8:32 27.09.2020' },

    { id: 145, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 141, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'unread', data: '13:47 25.06.2020' },
    { id: 140, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 138, title: 'Запрос 302 от 25.08.2020', type: 'application', number: 302, status: 'read', data: '13:47 25.06.2020' },
    { id: 135, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 130, title: 'Запрос 298 от 13.05.2020', type: 'application', number: 298, status: 'read', data: '13:47 25.06.2020' },
    { id: 129, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 121, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 110, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 106, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 105, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 103, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 98, title: 'как заказать выплаты', type: 'message', number: null, status: 'read', data: '14:12 12.08.2020' },
    { id: 96, title: 'Не принята заявка на выплату', type: 'message', number: null, status: 'read', data: '13:47 12.08.2020' },
    { id: 92, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 89, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 88, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 86, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 82, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 79, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 78, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 73, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 71, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 61, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 60, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 59, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 52, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 49, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 48, title: 'как заказать выплаты', type: 'message', number: null, status: 'read', data: '14:12 12.08.2020' },
    { id: 46, title: 'Не принята заявка на выплату', type: 'message', number: null, status: 'read', data: '13:47 12.08.2020' },
    { id: 41, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 36, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 21, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 11, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' },
    { id: 8, title: 'Не обновляется список акций', type: 'message', number: null, status: 'read', data: '13:47 30.06.2020' },
    { id: 1, title: 'Регистрация завершена не до конца', type: 'message', number: null, status: 'read', data: '13:47 25.06.2020' }
  ],

  messagesFake: [{
    id: 1,
    data: '27 сентября 2020',
    type: 'sent',
    text: 'Где мои бабки, уроды??????',
    name: null,
    time: '10:28',
    status: 'read'
  },{
    id: 3,
    data: '29 сентября 2020',
    type: 'sent',
    text: 'Ну и чего молчите???',
    name: null,
    time: '22:45',
    status: 'read'
  },{
    id: 5,
    data: null,
    type: 'received',
    text:'Да пошел ты. Не пиши сюда!!!',
    name: 'Иванушков Александр',
    avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
    time: '22:45',
    status: 'read'
  },{
    id: 7,
    data: null,
    type: 'received',
    text:'Удали приложение!!!',
    name: 'Иванушков Александр',
    avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
    time: '22:46',
    status: 'read'
  },{
    id: 9,
    data: null,
    type: 'sent',
    text: 'Я буду жаловаться!',
    name: null,
    time: '22:48',
    status: 'notdelivered'
  },{
    id: 12,
    data: null,
    type: 'sent',
    text: 'Это беспредел',
    name: null,
    time: '22:48',
    status: 'notread'
  },{
    id: 13,
    data: null,
    type: 'received',
    text:'И что ты такой твердый?!!',
    name: 'Иванушков Александр',
    avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
    time: '22:52',
    status: 'unread'
  },{
    id: 24,
    data: null,
    type: 'received',
    text:'Проваливай',
    name: 'Иванушков Александр',
    avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
    time: '22:52',
    status: 'unread'
  },{
    id: 26,
    data: null,
    type: 'received',
    text:'И выкинь телефон',
    name: 'Иванушков Александр',
    avatar: 'https://cdn.framework7.io/placeholder/people-100x100-7.jpg',
    time: '22:52',
    status: 'unread',
  }],
  isUnreadTicket: true
}

const getters = {}

const actions = {
  async ticketList ({ rootState, commit }, params = {}) {
    try {console.log(rootState)
      let { page, rows, filter } = params
      page = page && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1
      rows = rows && !isNaN(parseInt(rows, 10)) ? parseInt(rows, 10) : 10
      filter = filter ? filter : {}

      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const items = []
          const start = (page - 1) * rows
          const end = rows * page < rootState.base.tickets.length ? rows * page : rootState.base.tickets.length

          for (let i = start; i < end; i++) {
            items.push(rootState.base.tickets[i])
          }

          const data = {
            items: items,
            count: rootState.base.tickets.length
          }
          resolve({
              status: '1',
              data,
              errorCode: '',
              errorMessage: ''
          })
        }, 1000)
      }))()
          // const { data, status, errorMessage } = await api.get('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }
      const { items, count } = data
      commit('setTickets', items || [])
      if (count) {
        commit('setCountTickets', count)
      }
      return true
    } catch (err) {
      throw err
    }
  },
  async getTicket ({rootState, commit}, ticketId) {
    try {
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const s = rootState.base.tickets.find(x => parseInt(x.id, 10) === parseInt(ticketId, 10))
          let messages = []
          let dat = null
          if ((s.messages).length > 0) {
            messages = s.messages.map(x => ({...x, data: x.data != dat ? x.data : null}))
          }
            const data = {
              ticketId: ticketId,
              ticketTitle: s ? s.title : null,
              ticketType:  s ? s.type : null,
              number:  s ? s.number : null,
              messages
            }
            resolve({
                status: '1',
                data,
                errorCode: '',
                errorMessage: ''
            })
        }, 1000)
      }))()
          // const { data, status, errorMessage } = await api.get('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      commit('setTicket',  {ticketId: data.ticketId || null, ticketTitle: data.ticketTitle || '', ticketType: data.ticketType || null, number: data.number || null, messages: data.messages || []})
      return true
    } catch (err) {
      throw err
    }
  },

  async addMessage ({state, rootState, commit}, messages) {
    try {
      for (const message of messages) {
            const { data, status, errorMessage } = await (async() => new Promise(resolve => {
                setTimeout(() => {
                  const ticketId =  parseInt(rootState.base.tickets[0].id, 10) + 2

                  const messageId = parseInt(rootState.base.messageId, 10) + 2
                  commit('base/setMessageId', messageId, { root: true })

                  if (state.ticketId === null) {
                    commit('base/setTicket', {
                      ticket: {
                        id: ticketId,
                        title: state.ticketTitle,
                        type: state.ticketType,
                        number: state.ticketApplicationNumber,
                        status: 'notanswered'
                      }, 
                      message: {...message, messageId, status: 'notread'}
                    }, { root: true })
                  } else {
                    commit('base/setMessage', {
                      ticketId: state.ticketId, 
                      message: {...message, messageId, status: 'notread'}
                    }, { root: true })
                  }

                  const data = {
                    ticketId,
                    message: {...message, id: messageId, status: 'notread'}
                  }
                  resolve({
                      status: '1',
                      data,
                      errorCode: '',
                      errorMessage: ''
                  })
              }, 1000)
            }))()
            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (data && String(status) === '1') {
              commit('setMessage', { id: message.id, message: data.message })

              commit('setTicketId', data.ticketId)
              // commit('setAddTicketFake', {
              //     id: data.ticketId,
              //     title: state.ticketTitle,
              //     type: state.ticketType,
              //     number: state.ticketType === 'application' ? state.ticketApplicationNumber : '',
              //     status: 'notanswered',
              //     data: '06.10.2020'
              //   })
            }
      }
      return true
    } catch (err) {
      throw err
    }
  }

}

const mutations = {
    setTickets (state, tickets) {
      tickets = (tickets && Array.isArray(tickets) && tickets.length > 0) ? tickets : []
      if (tickets.length === 0) {
          state.tickets = []
          state.countTickets = 0
      } else {
        state.tickets = [...state.tickets, ...tickets]



        // tickets.forEach(ticket => {
        //   state.tickets.push(ticket)
        // })
      }
    },
    setCountTickets (state, count) {
      state.countTickets = count
    },
    setMessages (state, messages) {
      state.messages = messages
    },
    setTicketId (state, ticketId) {
      state.ticketId = ticketId
    },
    setTicket (state, {ticketId, ticketTitle, ticketType, number, messages}) {
      state.ticketId = parseInt(ticketId, 10)
      state.ticketTitle = ticketTitle
      state.ticketType = ticketType
      state.ticketApplicationNumber = number
      state.messages = messages
    },
    clearTicket (state) {
      state.ticketId = null
      state.ticketTitle = ''
      state.ticketType = null
      state.ticketApplicationNumber = null
      state.messages = []
    },
    setMessage (state, { id, message }) {
      const messages = [...state.messages]
      const mes = messages.find(x => String(x.id) === String(id))
      if (mes) {
        mes.id = message.id
        mes.status = message.status
      }
      state.messages = messages
    },
    setTicketTitle (state, title) {
      state.ticketTitle = title
    },
    setTicketId (state, ticketId) {
      state.ticketId = ticketId
    },
    setTicketType (state, ticketType) {
      state.ticketType = ticketType
    },
    setTicketType (state, number) {
      state.ticketApplicationNumber = number
    },

    



    // временное для имитации добавления тикета
    setAddTicketFake (state, ticket) {
      state.ticketsFake.unshift({
        id: ticket.id,
        title: ticket.title,
        type: ticket.type,
        number: ticket.number,
        status: ticket.status,
        data: ticket.data
      })
    }
    
}
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }