import api from '../js/api.js'

const state = {}

const getters = {}

const actions = {
  async getList ({ rootState }, params = {}) {
    try {
      if (rootState.auth.userId === null) {
        new Error('no-auth')
      }

      let { page, rows, order, by, search } = params
      page = page && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1
      rows = rows && !isNaN(parseInt(rows, 10)) ? parseInt(rows, 10) : 10
      search =  search || null
      order = order ? order : 'actionId'
      by = by ? by : 'desc'
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const items = []
          const actions = [...rootState.base.actions]

          // сортируем
          actions.sort((a, b) => {
            if ((by !== 'asc' && a[order] > b[order]) || (by === 'asc' && a[order] < b[order])) {
              return 1
            }
            if ((by !== 'asc' && a[order] < b[order]) || (by === 'asc' && a[order] > b[order])) {
              return -1
            }
            return 0
          })

          const start = (page - 1) * rows
          const end = rows * page < actions.length ? rows * page : actions.length

          for (let i = start; i < end; i++) {
            items.push(actions[i])
          }

          resolve({
            status: '1',
            data: {
              items,
              totalItems: (actions || []).length
            },
            errorCode: '',
            errorMessage: ''
          })
        }, 500)
      }))()

      // const { data, status, errorMessage } = await api.get('/actions/', { page, rows, order, by, search })
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      return data
    } catch (err) {
      throw err
    }
  },

  async getData ({rootState}, id) {
    try {
      if (!id || isNaN(parseInt(id, 10))) {
        throw new Error('Некорректные данные')
      }
      const { data, status, errorMessage } = await (() => new Promise((resolve, reject) => {
        setTimeout(() => {
          const action = [...rootState.base.actions].find(x => parseInt(x.actionId, 10) === parseInt(id, 10))
          if (!action) {
            reject(new Error('Акция не найдена'))
          }

          // Одобренные бонусы за акцию
          let totalBonus = 0

          // количество, которое не вошло в состав оплаченого
          let quantityAvailable = 0
          let quantitySubmitted = 0// товары не имеющие статуса success или denied
          let quantityRequired = 0


          // просчитываем показатели для юонусных акций
          if (action.actionType === 'bonus') {
            const basketOrder = rootState.base.basket.filter(x => x.orderId !== null && x.productId !== null && parseInt(x.actionId, 10) === parseInt(action.actionId, 10) && x.status === 'success')
            if ((basketOrder || []).length > 0) {
              totalBonus = (basketOrder || []).length === 1 ? basketOrder[0].total : basketOrder.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
            }
          }

          // просчитываем показатели для количественных акций
          if (action.actionType === 'quantity') {
            // расчет засчитанных баллов
            const basketOrder = rootState.base.basket.filter(x => x.orderId !== null && x.productId === null && parseInt(x.actionId, 10) === parseInt(action.actionId, 10) && x.status === 'success')
            const successBonusQuantity = (basketOrder || []).length > 0 ? (basketOrder || []).length === 1 ? basketOrder[0].quantity : basketOrder.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.quantity) + item.quantity) : 0

            // ищем submitted - товары не имеющие статуса success или denied (в боевом надо проверять на даты действия акции)
            const basketSubmitted = rootState.base.basket.filter(x => x.orderId !== null && x.productId !== null && action.products.includes(x.productId) && x.status !== 'denied' && x.status !== 'success')
            if ((basketSubmitted || []).length > 0) {
              quantitySubmitted = (basketSubmitted || []).length === 1 ? basketSubmitted[0].quantity : basketSubmitted.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.quantity) + item.quantity)
            }
            // щитаем все одобренный товары, подходящие в акцию, вычитаем одобренные
            const basketSuccess = rootState.base.basket.filter(x => x.orderId !== null && x.productId !== null && action.products.includes(x.productId) && x.status === 'success')
            const quantitySuccess = (basketSuccess || []).length > 0 ? (basketSuccess || []).length === 1 ? basketSuccess[0].quantity : basketSuccess.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.quantity) + item.quantity) : 0
            
            // считаем сколько набрано
            quantityAvailable = quantitySuccess - successBonusQuantity

            // Считаем необходимый минимум без расчета на усмотрение
            quantityRequired = action.step - quantityAvailable % action.step
          }

          const products = rootState.base.products.filter(x => action.products.includes(x.productId)).map(product => {
            let bonus = 0
            if((product.actions || []).length > 0) {
              let sAction = product.actions.find(x => !x.quantity)
              if (sAction) {
                bonus = sAction.bonus
              }
            }
            return {
              productId: product.productId,
              title: product.title,
              bonus,
              src: product.src
            }
          })

          resolve({
            status: '1',
            data: {...action, totalBonus, quantityAvailable, quantitySubmitted, quantityRequired, products},
            errorCode: '',
            errorMessage: ''
          })
        }, 500)
      }))()

      // const { data, status, errorMessage } = await api.get('/actions/', { page, rows, order, by, search })
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      return data
    } catch (err) {
      throw err
    }
  },









// удалить после обработки  sales.vue
  async actionsList ({commit, dispatch, state}) {
    try {
      // эмулируем моковые данные с закрытой точкой
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const actions = [
            {
              actionId: 1,
              title: "Мега-бонусы от BAST",
              description: 'Продавая товары участвующие в акции "Мега-бонусы от BAST" Вы получаете бонусы с каждой проданной единицы товара'
            },{
              actionId: 2,
              title: "Веселые продажи с ProДеталь",
              description: 'Продавая товары участвующие в акции "Веселые продажи с ProДеталь" Вы получаете бонусы с каждой проданной единицы товара'
            }
          ]
          resolve({
            status: '1',
            data: actions,
            errorCode: '',
            errorMessage: ''
          })
        }, 2000)
      }))()

      // const { data, status, errorMessage } = await api.post('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      if (Array.isArray(data) && data.length > 0) {
        commit('setActions', data)
        return true
      }
      commit('setActions', [])
      return true
    } catch (err) {
      throw err
    }
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
