import api from '../js/api.js'

const state = {
  sales: [],
  elementCount: 0
}

const getters = {}

const actions = {
  async salesList ({commit, dispatch, state}) {
    try {
      // эмулируем моковые данные с закрытой точкой
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const actions = [
            {
              salesId: 1,
              data: '29.07.2020',
              bonus: 0,
              checkSrc: '',
              status: 'denial', // denial - отказ, success - одобренные, partially - частично одобрен
              products: [
                {
                  productId: 1,
                  title: "Амортизатор для а/м ВАЗ 2123 перед. (масляный) ''PROдеталь''",
                  bonus: 100,
                  src: "https://b2motor.ru/upload/resize_cache/iblock/2fd/140_140_1/2fd793ac04dd86e16f27a7788706fdf7.jpg",
                  barcode: '',
                  brand: 'ProДеталь',
                  articule: 'DPAVP2123M',
                  productTyle: 'Амортизатор',
                  code: '762702',
                  actionId: 2,
                  checkSrc: '',
                  status: 'denial', // denial - отказ, success - одобренные
                  comments: 'Товар в чеке не соответствует '
                }
              ]
            }, {
              salesId: 1,
              data: '25.07.2020',
              bonus: 160,
              checkSrc: '',
              status: 'success', // denial - отказ, success - одобренные, partially - частично одобрен
              products: [
                {
                  productId: 1,
                  title: "Амортизатор для а/м ВАЗ 2123 перед. (масляный) ''PROдеталь''",
                  bonus: 100,
                  src: "https://b2motor.ru/upload/resize_cache/iblock/2fd/140_140_1/2fd793ac04dd86e16f27a7788706fdf7.jpg",
                  barcode: '',
                  brand: 'ProДеталь',
                  articule: 'DPAVP2123M',
                  productTyle: 'Амортизатор',
                  code: '762702',
                  actionId: 2,
                  checkSrc: '',
                  status: 'denial', // denial - отказ, success - одобренные
                  comments: 'Товар в чеке не соответствует '
                },{
                  productId: 5,
                  title: "Патрубок радиатора для а/м ГАЗ 3302 дв.Chrysler (к-кт 5шт.) ''ProДеталь''",
                  bonus: 60,
                  src: "https://b2motor.ru/upload/resize_cache/iblock/d74/140_140_1/d74d3ae887288991c0aaaccb2343773b.jpg",
                  barcode: '',
                  brand: 'ProДеталь',
                  articule: 'DPPR330288',
                  productTyle: 'Патрубок радиатора',
                  code: ' 758242',
                  actionId: 2
                }
              ]
            }
          ]
          resolve({
            status: '1',
            data: actions,
            errorCode: '',
            errorMessage: ''
          })
        }, 2000)
      }))()

      // const { data, status, errorMessage } = await api.post('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      if (Array.isArray(data) && data.length > 0) {
        commit('setActions', data)
        return true
      }
      commit('setActions', [])
      return true
    } catch (err) {
      throw err
    }
  },
  async getElementCount ({rootState, commit}) {
    try {
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          products = []
          resolve({
            status: '1',
            data: rootState.base.basket.filter(x => x.orderId === null).length,
            errorCode: '',
            errorMessage: ''
          })
        }, 500)
      }))()

      // const { data, status, errorMessage } = await api.post('/point/close/', {})
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }
      commit('setElementCount', data)
      return true
    } catch (err) {
      throw err
    }
  }
}

const mutations = {
  setActions (state, actions) {
    state.actions = actions
  },
  setElementCount (state, count) {
    state.elementCount = count
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
