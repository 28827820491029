<template>
    <f7-page name="Ticket" bg-color="white" messages-content>
        <!-- Top Navbar -->
        <f7-navbar :title="ticketTitle" back-link="Back" ignore-cache></f7-navbar>

        <f7-messagebar
            :placeholder="placeholder"
            ref="messagebar"
            :attachments-visible="attachmentsVisible"
            :sheet-visible="sheetVisible"
            @focus="messagebarFocus"
        >
            <f7-link
                icon-ios="f7:camera_fill"
                icon-aurora="f7:camera_fill"
                icon-md="material:camera_alt"
                slot="inner-start"
                @click="sheetOpen"
            ></f7-link>
            <f7-link
                icon-ios="f7:arrow_up_circle_fill"
                icon-aurora="f7:arrow_up_circle_fill"
                icon-md="material:send"
                slot="inner-end"
                @click="sendMessage"
            ></f7-link>
            <f7-messagebar-attachments>
                <f7-messagebar-attachment
                    v-for="(image, index) in attachments"
                    :key="index"
                    :image="image"
                    @attachment:delete="deleteAttachment(image)"
                ></f7-messagebar-attachment>
            </f7-messagebar-attachments>
            <f7-messagebar-sheet>
                <f7-messagebar-sheet-image
                    v-for="(image, index) in images"
                    :key="index"
                    :image="image"
                    :checked="attachments.indexOf(image) >= 0"
                    @change="handleAttachment"
                ></f7-messagebar-sheet-image>
            </f7-messagebar-sheet>
        </f7-messagebar>

        <f7-messages ref="messages" @click="messagebarFocus">
            <f7-messages-title v-show="!loading && !loadingGlobal" class="title-messages text-color-primary">{{ ticketTitle }}</f7-messages-title>
            <f7-messages-title v-show="loading || loadingGlobal" class="title-messages text-color-primary skeleton-text skeleton-effect-blink"></f7-messages-title>
                        
            <f7-message v-show="loading || loadingGlobal"
                class="skeleton-text skeleton-effect-blink"
                :first="true"
                :last="true"
                :tail="true"
            >
                <div class="text-color-primary" slot="text">Lorem ipsum dolor</div>
                <div class="bubble-end text-color-primary" slot="bubble-end">00:00</div>
                <f7-skeleton-block slot="avatar" class="bg-color-primary" style="width: 30px; height: 30px; border-radius: 50%" ></f7-skeleton-block>
            </f7-message>
            <f7-message v-show="loading || loadingGlobal"
                class="skeleton-text skeleton-effect-blink" 
                type="received"
                :first="true"
                :last="true"
                :tail="true"
            >
                <div slot="text" style="color: #e5e5ea !important;">Lorem ipsum dolor</div>
                <f7-skeleton-block slot="avatar" style="width: 30px; height: 30px; border-radius: 50%; background: #e5e5ea !important;" ></f7-skeleton-block>
                <div class="bubble-end" slot="bubble-end" style="color: #e5e5ea !important;">00:00</div>
            </f7-message>
            <f7-message v-show="loading || loadingGlobal"
                class="skeleton-text skeleton-effect-blink"
                :first="true"
                :last="true"
                :tail="true"
            >
                <div class="text-color-primary" slot="text">Lorem ipsum dolor</div>
                <div class="bubble-end text-color-primary" slot="bubble-end">00:00</div>
                <f7-skeleton-block slot="avatar" class="bg-color-primary" style="width: 30px; height: 30px; border-radius: 50%" ></f7-skeleton-block>
            </f7-message>
            <f7-message v-show="loading || loadingGlobal"
                class="skeleton-text skeleton-effect-blink" 
                type="received"
                :first="true"
                :last="true"
                :tail="true"
            >
                <div slot="text" style="color: #e5e5ea !important;">Lorem ipsum dolor</div>
                <f7-skeleton-block slot="avatar" style="width: 30px; height: 30px; border-radius: 50%; background: #e5e5ea !important;" ></f7-skeleton-block>
                <div class="bubble-end" slot="bubble-end" style="color: #e5e5ea !important;">00:00</div>
            </f7-message>


            <f7-message 
                v-show="!loading && !loadingGlobal"
                v-for="(message, index) in messages"
                :key="message.id"
                :type="message.type"
                :image="message.image"
                :text="message.text"
                :first="isFirstMessage(messages, message, index)"
                :last="isLastMessage(messages, message, index)"
                :tail="isTailMessage(messages, message, index)"
            >
                <div v-if="message.data" slot="content-start" class="data-messages"><b>{{ message.data }}</b></div>
                <div v-if="message.avatar" class="message-avatar" slot="avatar" :style="`background-image: url('${message.avatar}');`"></div>
                <f7-icon v-else slot="avatar" f7="person_crop_circle_fill" size="36px" color="primary"></f7-icon>
                <div v-if="message.name" slot="name">{{ message.name }}</div>
                <div class="bubble-end" slot="bubble-end">{{ message.time }}
                    <f7-icon v-if="message.type !== 'received' && message.status && message.status === 'notread'" f7="circle_fill" size="6px"></f7-icon>
                    <f7-icon v-if="message.type !== 'received' && (!message.status || message.status === 'notdelivered')"  f7="xmark" size="6px"></f7-icon>
                </div>
            </f7-message>
           
            <f7-messages-title v-show="!loading && !loadingGlobal" v-if="unreadMessages.length > 0" class="unread-messages bg-color-gray text-color-black">Непрочитанные сообщения</f7-messages-title>
            
            <f7-message
                v-show="!loading && !loadingGlobal"
                v-for="(message, index) in unreadMessages"
                :key="message.id"
                :type="message.type"
                :name="message.name"
                :image="message.image"
                :text="message.text"
                :first="isFirstMessage(unreadMessages, message, index)"
                :last="isLastMessage(unreadMessages, message, index)"
                :tail="isTailMessage(unreadMessages, message, index)"
            >
                <div v-if="message.data" slot="content-start" class="data-messages"><b>29 сентября 2020</b></div>
                <div v-if="message.avatar" class="message-avatar" slot="avatar" :style="`background-image: url('${message.avatar}');`"></div>
                <f7-icon v-else slot="avatar" f7="person_crop_circle_fill" size="36px" color="primary"></f7-icon>
                <div class="bubble-end" slot="bubble-end">{{ message.time }}</div>
            </f7-message>
        </f7-messages>
        <f7-sheet
          style="height:auto; --f7-sheet-bg-color: #fff;"
          :opened="sheetOpened" @sheet:closed="sheetClosed"
          backdrop
        >
          <f7-page-content>
            <f7-block class="block-mes">Прордолжить набор или подтвердить продажу?</f7-block>
      

              <f7-list >
                <f7-list-input
                    type="text"
                    placeholder="Введите тему вопроса"
                    required
                    validate
                    clear-button
                    :value="ticketTitleForm"
                    @change="ticketTitleForm = $event.target.value"
                >
                </f7-list-input>
              </f7-list>
            <f7-block>
              <f7-button raised outline @click="addTicket" large>Продолжить</f7-button>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
    </f7-page>
</template>

<script>
export default {
    name: 'Ticket',
    computed: {
        loadingGlobal () {
            return this.$store.state.loading
        },
        attachmentsVisible() {
            return this.attachments.length > 0;
        },
        placeholder() {
            return this.attachments.length > 0 ? 'Сообщение или отправьте' : 'Сообщение';
        },
        messages () {
            let messages = []
            if (this.$store.state.support.messages.length > 0) {
                this.$store.state.support.messages.forEach(message => {
                    if (message.status !== 'unread') {
                        messages.push(message)
                    }
                })
            }
            return messages
        },
        unreadMessages () {
            let messages = []
            if (this.$store.state.support.messages.length > 0) {
                this.$store.state.support.messages.forEach(message => {
                    if (message.status === 'unread') {
                        messages.push(message)
                    }
                })
            }
            return messages
        },
        ticketTitle () {
            return this.$store.state.support.ticketTitle || null
        }
    },
    data () {
        return {
            a: 1,
            loading: true,
            attachments: [],
            sheetVisible: false,
            chekData: null,
            ticketTitleForm: '',
            images: [
                'https://cdn.framework7.io/placeholder/cats-300x300-1.jpg',
                'https://cdn.framework7.io/placeholder/cats-200x300-2.jpg',
                'https://cdn.framework7.io/placeholder/cats-400x300-3.jpg',
                'https://cdn.framework7.io/placeholder/cats-300x150-4.jpg',
                'https://cdn.framework7.io/placeholder/cats-150x300-5.jpg',
                'https://cdn.framework7.io/placeholder/cats-300x300-6.jpg',
                'https://cdn.framework7.io/placeholder/cats-300x300-7.jpg',
                'https://cdn.framework7.io/placeholder/cats-200x300-8.jpg',
                'https://cdn.framework7.io/placeholder/cats-400x300-9.jpg',
                'https://cdn.framework7.io/placeholder/cats-300x150-10.jpg',
            ],
            errorMesLocalText: 'Запрос не найден, но вы можете оставить текстовое обращение. Введите тему обращения.',
            errorMesLocal: false,
            sheetOpened: false
        }
    },
    methods: {
        isFirstMessage(messages, message, index) {
            const previousMessage = messages[index - 1]
            // if (message.isTitle) return false
            if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true
            return false
        },
        isLastMessage(messages, message, index) {
            const nextMessage = messages[index + 1]
            // if (message.isTitle) return false
            if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true
            return false
        },
        isTailMessage(messages, message, index) {
            const nextMessage = messages[index + 1]
            // if (message.isTitle) return false
            if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true
            return false
        },
        deleteAttachment(image) {
            const index = this.attachments.indexOf(image)
            this.attachments.splice(index, 1)[0] // eslint-disable-line
        },
        handleAttachment(e) {
            const index = this.$$(e.target).parents('label.checkbox').index()
            const image = this.images[index]
            if (e.target.checked) {
                // Add to attachments
                this.attachments.unshift(image)
            } else {
                // Remove from attachments
                this.attachments.splice(this.attachments.indexOf(image), 1)
            }
        },
        async sendMessage () {
            const messagebar = this.$refs.messagebar.f7Messagebar
            const text = messagebar.getValue().replace(/\n/g, '<br>').trim();

            const messagesToSend = []
            this.attachments.forEach((attachment) => {
                messagesToSend.push({
                    id: `a${this.a}`,
                    image: attachment,
                })
                this.a++
            })
            if (text.trim().length) {
                messagesToSend.push({
                    id: `a${this.a}`,
                    text,
                })
                this.a++
            }
            if (messagesToSend.length === 0) {
                return
            }

            // Reset attachments
            this.attachments = [];
            // Hide sheet
            this.sheetVisible = false;
            // Clear area
            messagebar.clear();
            // Focus area
            if (text.length) messagebar.focus();
            // Send message
            console.log('...messagesToSend', messagesToSend)
            this.$store.commit('support/setMessages', [...this.messages, ...messagesToSend])
            await this.$store.dispatch('support/addMessage', messagesToSend)
            // this.messages.push(...messagesToSend);
        },
        messagebarFocus () {
            if (this.unreadMessages.length > 0) {
                let unreadMessages = []
                this.unreadMessages.forEach(message => {
                    unreadMessages.push({...message, status: 'read'})
                })
                this.$store.commit('support/setMessages', [...this.messages, ...unreadMessages])
            }
        },
        sheetOpen () {
            this.messagebarFocus()
            this.sheetVisible = !this.sheetVisible
        },
        sheetClosed () {
            if (String(this.$store.state.support.ticketTitle) === 'null' || String(this.$store.state.support.ticketTitle).length === 0) {
                this.$f7router.back()
            }
            this.sheetOpened = false
        },
        addTicket () {
            console.log('addTicket', this.ticketTitleForm)
            if (String(this.ticketTitleForm) === 'null' || String(this.ticketTitleForm).trim().length === 0) {
                return false
            }
            this.$store.commit('support/setTicketTitle', this.ticketTitleForm)
            this.sheetOpened = false
        }
    },
    async mounted () {
        this.loading = true
        this.$store.commit('support/clearTicket')
        this.errorMesLocal = false,
        this.sheetOpened = false
        

        if (this.$f7route.params.hasOwnProperty('ticketId')) {
            await this.$store.dispatch('support/getTicket', this.$f7route.params.ticketId)
        } else {
            if (this.$f7route.query.hasOwnProperty('type')) {
                if (this.$f7route.query.type === 'application') {
                    if (this.$f7route.query.hasOwnProperty('number') && this.$f7route.query.number.length > 0) {
                        // лезем проверяем есть ли такой запрос и есть ли уже открытый тикет на него, если тикета нет, то создаем новый, при первом сообщении сохраняем как обычно
                        const ticket = await this.$store.dispatch('applications/chekData', this.$f7route.query.number)
                        if (ticket) {
                            this.$store.commit('support/setTicketTitle', `Запрос ${ticket.id} от ${ticket.data}`)
                            this.$store.commit('support/setTicketType', `application`)
                            this.$store.commit('support/setTicketType', this.$f7route.query.number)
                            
                        } else {
                            // выдать ошибку и попросить ввести тему запроса или вернуться назад
                            this.$store.commit('support/setTicketType', `message`)
                            this.errorMesLocal = true,
                            this.sheetOpened = true
                        }
                    } else {
                        // выдать ошибку и попросить ввести тему запроса или вернуться назад
                        this.$store.commit('support/setTicketType', `message`)
                        this.errorMesLocal = true,
                        this.sheetOpened = true
                    }
                } else if (this.$f7route.query.type === 'message') {
                    if (this.$f7route.query.hasOwnProperty('title')) {
                        this.$store.commit('support/setTicketTitle', this.$f7route.query.title)
                        this.$store.commit('support/setTicketType', `message`)
                    } 
                } else {
                    // не выдавая ошибки спросить заголовок
                    this.$store.commit('support/setTicketType', `message`)
                    this.sheetOpened = true
                }
            } else {
                // не выдавая ошибки спросить заголовок
                this.$store.commit('support/setTicketType', `message`)
                this.sheetOpened = true
            }
        }


        this.loading = false
    }
}
</script>

<style scoped>
.messages-content{padding-top: 46px;}
.messages{padding-bottom: calc(var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px));}
.bubble-end{text-align: right;font-size: 12px;}
.data-messages{margin-bottom: 5px;
  line-height: 1;
  color: var(--f7-messages-title-text-color);
  font-size: var(--f7-messages-title-font-size);
  font-weight: 100;
}
.title-messages{font-size: 18px;}
.unread-messages{padding: 5px;}
.skeleton-text .message-sent .message-avatar {display: none;}
.skeleton-text, .skeleton-text{color:var(--f7-message-received-bg-color)!important;}
.sheet-modal-inner .block-mes {margin: 16px 0;}
.sheet-modal-inner .list {margin-top: 0;}
</style>