const state = {
    messageId: 45,
    tickets: [{
        id: 25,
        title: 'Запрос 346 от 27.09.2020',
        type: 'application',
        number: null,
        status: 'notanswered',
        data: '10:01 30.09.2020',
        messages: [{
            id: 42,
            data: '30.09.2020',
            type: 'sent',
            text: 'А почему мне незачли первую позицию?',
            name: null,
            time: '10:01',
            status: 'notread'
        }]
    },{
        id: 21,
        title: 'Как зарегистрировать рефералку?',
        type: 'message',
        number: null,
        status: 'unread',
        data: '18:45 30.06.2020',
        messages: [{
            id: 35,
            data: '30.06.2020',
            type: 'sent',
            text: 'Ну и чего молчите???',
            name: null,
            time: '18:45',
            status: 'read'
        },{
            id: 45,
            data: '01.07.2020',
            type: 'received',
            text:'Да пошел ты. Не пиши сюда!!!',
            name: 'Иванушков Александр',
            avatar: 'https://sun9-30.userapi.com/impf/c844520/v844520802/d9cdf/-EiPfyK-3TE.jpg?size=50x0&quality=88&crop=0,0,1536,1536&sign=20290a8c29645a92a764cb19c48d601f&ava=1',
            time: '09:50',
            status: 'unread'
        }]
    },{
        id: 18,
        title: 'Запрос 14 от 27.09.2020',
        type: 'application',
        number: 14,
        status: 'read',
        data: '13:25 27.09.2020',
        messages: [{
            id: 25,
            data: '27.09.2020',
            type: 'sent',
            text: 'Ну примите у меня подтверждение...плиииииз',
            name: null,
            time: '13:25',
            status: 'read'
        },{
            id: 30,
            data: '27.09.2020',
            type: 'received',
            text:'Ха, смотрите, он все еще на что то надеется',
            name: 'Калинин Александр',
            avatar: 'https://sun9-3.userapi.com/impf/h_5xpp2jPY1bG5ocR7eoIMpWYtxTeJVkLhlBEg/CtNuN-ObGVY.jpg?size=100x0&quality=88&crop=67,0,432,432&sign=0a6f328f05e64e6a5c3209bd00d49412&ava=1',
            time: '13:35',
            status: 'read'
          },{
            id: 32,
            data: '27.09.2020',
            type: 'received',
            text:'Скажи спасибо, что ответили!',
            name: 'Иванушков Александр',
            avatar: 'https://sun9-30.userapi.com/impf/c844520/v844520802/d9cdf/-EiPfyK-3TE.jpg?size=50x0&quality=88&crop=0,0,1536,1536&sign=20290a8c29645a92a764cb19c48d601f&ava=1',
            time: '13:35',
            status: 'read'
          }]
    },{
        id: 15,
        title: 'Почему не приходят деньги',
        type: 'message',
        number: null,
        status: 'read',
        data: '11:47 15.06.2020',
        messages: [{
            id: 18,
            data: '15.06.2020',
            type: 'sent',
            text: 'Где мои бабки, уроды??????',
            name: null,
            time: '11:47',
            status: 'read'
        },{
            id: 19,
            data: '25.05.2020',
            type: 'received',
            text:'Приложение тестовое....иди голову лечи!',
            name: 'Горгоц Евгений',
            avatar: 'https://sun9-20.userapi.com/impf/c622318/v622318854/3b77e/ae1xb2Y-_dU.jpg?size=100x0&quality=88&crop=577,136,1401,1401&sign=90f609a91ba443024c04a7fe7fb06267&ava=1',
            time: '09:12',
            status: 'read'
          }]
    },{
        id: 11,
        title: 'Как вывести деньги?',
        type: 'message',
        number: null,
        status: 'read',
        data: '12:24 08.06.2020',
        messages: [{
            id: 12,
            data: '08.06.2020',
            type: 'sent',
            text: 'Хочу денег!',
            name: null,
            time: '12:24',
            status: 'read'
        },{
            id: 13,
            data: '09.06.2020',
            type: 'sent',
            text: 'Ну и чего молчите???',
            name: null,
            time: '22:45',
            status: 'read'
        },{
            id: 15,
            data: '10.06.2020',
            type: 'received',
            text:'Да пошел ты. Не пиши сюда!!!',
            name: 'Иванушков Александр',
            avatar: 'https://sun9-30.userapi.com/impf/c844520/v844520802/d9cdf/-EiPfyK-3TE.jpg?size=50x0&quality=88&crop=0,0,1536,1536&sign=20290a8c29645a92a764cb19c48d601f&ava=1',
            time: '09:12',
            status: 'read'
        }]
    },{
        id: 8,
        title: 'Не обновляется список акций',
        type: 'message',
        number: null,
        status: 'read',
        data: '13:47 30.05.2020',
        messages: [{
            id: 10,
            data: '30.05.2020',
            type: 'received',
            text:'Поздравляем, процес регистрации прошел успешно. Добро пожаловать в клуб!',
            name: 'Шульпина Евгения',
            avatar: 'https://sun9-65.userapi.com/impf/c851128/v851128593/42662/iughB4DQSUk.jpg?size=100x0&quality=88&crop=1,85,762,762&sign=6e84658667ba59a43a67137d36830c14&ava=1',
            time: '13:47',
            status: 'read'
          }]
    },{
        id: 1,
        title: 'Регистрация завершена не до конца',
        type: 'message',
        number: null,
        status: 'read',
        data: '09:12 25.05.2020',
        messages: [{
            id: 5,
            data: '25.05.2020',
            type: 'received',
            text:'Для полноценного участия в бонусной программе вам необходимо полностью заполнить ваш профиль во вкладке профилей',
            name: 'Шульпина Евгения',
            avatar: 'https://sun9-65.userapi.com/impf/c851128/v851128593/42662/iughB4DQSUk.jpg?size=100x0&quality=88&crop=1,85,762,762&sign=6e84658667ba59a43a67137d36830c14&ava=1',
            time: '09:12',
            status: 'read'
          }]
    }],
    
    answer: [{
        id: 1,
        text:'Удали приложение!!!',
        name: 'Калинин Александр',
        avatar: 'https://sun9-3.userapi.com/impf/h_5xpp2jPY1bG5ocR7eoIMpWYtxTeJVkLhlBEg/CtNuN-ObGVY.jpg?size=100x0&quality=88&crop=67,0,432,432&sign=0a6f328f05e64e6a5c3209bd00d49412&ava=1',
    },{
        id: 2,
        text:'Да пошел ты. Не пиши сюда!!!',
        name: 'Иванушков Александр',
        avatar: 'https://sun9-30.userapi.com/impf/c844520/v844520802/d9cdf/-EiPfyK-3TE.jpg?size=50x0&quality=88&crop=0,0,1536,1536&sign=20290a8c29645a92a764cb19c48d601f&ava=1',
    },{
        id: 3,
        text:'И что ты такой твердый?!!',
        name: 'Иванушков Александр',
        avatar: 'https://sun9-30.userapi.com/impf/c844520/v844520802/d9cdf/-EiPfyK-3TE.jpg?size=50x0&quality=88&crop=0,0,1536,1536&sign=20290a8c29645a92a764cb19c48d601f&ava=1',
    },{
        id: 4,
        text:'Проваливай',
        name: 'Виноградов Павел',
        avatar: 'https://vk.com/images/camera_100.png?ava=1',
    },{
        id: 5,
        text:'Я тебя по IP забаню',
        name: 'Калинин Александр',
        avatar: 'https://sun9-3.userapi.com/impf/h_5xpp2jPY1bG5ocR7eoIMpWYtxTeJVkLhlBEg/CtNuN-ObGVY.jpg?size=100x0&quality=88&crop=67,0,432,432&sign=0a6f328f05e64e6a5c3209bd00d49412&ava=1',
    },{
        id: 6,
        text:'Денег нет, но Вы держитесь',
        name: 'Шульпина Евгения',
        avatar: 'https://sun9-65.userapi.com/impf/c851128/v851128593/42662/iughB4DQSUk.jpg?size=100x0&quality=88&crop=1,85,762,762&sign=6e84658667ba59a43a67137d36830c14&ava=1',
    },{
        id: 7,
        text:'Ты сума сошел??? Я ж на ПЫХЕ пишу!!!',
        name: 'Горгоц Евгений',
        avatar: 'https://sun9-20.userapi.com/impf/c622318/v622318854/3b77e/ae1xb2Y-_dU.jpg?size=100x0&quality=88&crop=577,136,1401,1401&sign=90f609a91ba443024c04a7fe7fb06267&ava=1',
    },{
        id: 8,
        text:'Ой, я ничего не поняла',
        name: 'Шульпина Евгения',
        avatar: 'https://sun9-65.userapi.com/impf/c851128/v851128593/42662/iughB4DQSUk.jpg?size=100x0&quality=88&crop=1,85,762,762&sign=6e84658667ba59a43a67137d36830c14&ava=1',
    },{
        id: 9,
        text:'Закрывай уже топик, не позорься!',
        name: 'Горгоц Евгений',
        avatar: 'https://sun9-20.userapi.com/impf/c622318/v622318854/3b77e/ae1xb2Y-_dU.jpg?size=100x0&quality=88&crop=577,136,1401,1401&sign=90f609a91ba443024c04a7fe7fb06267&ava=1',
    },{
        id: 10,
        text:'Мы 1С-ники народ простой',
        name: 'Виноградов Павел',
        avatar: 'https://vk.com/images/camera_100.png?ava=1',
    }],

    applicationList: [{
        id: 12, 
        data: '27.09.2020',
        bonusTotal: 100, 
        bonusSuccess: 0,
        status: 'sent',// отправлен
        ticket: 149,
        document: 'https://labrador.ru/ipb/uploads/monthly_2016_01/569e155e4abd5_.jpg.d633673a1a0645f52bddb91299e26d99.jpg',
        comment: '',
    },{
        id: 8, 
        data: '13.05.2020',
        bonusTotal: 70, 
        bonusSuccess: 50,
        status: 'success',// закрыт
        ticket: null,
        document: 'https://labrador.ru/ipb/uploads/monthly_2016_01/569e155e4abd5_.jpg.d633673a1a0645f52bddb91299e26d99.jpg',
        comment: 'По чеку невозможно подтверждение второго товара',
    },{
        id: 2, 
        data: '12.05.2020',
        bonusTotal: 250, 
        bonusSuccess: 250,
        status: 'success',// закрыт
        ticket: null,
        document: 'https://labrador.ru/ipb/uploads/monthly_2016_01/569e155e4abd5_.jpg.d633673a1a0645f52bddb91299e26d99.jpg',
        comment: '',
    },{
        id: 1, 
        data: '25.04.2020',
        bonusTotal: 120, 
        bonusSuccess: 120,
        status: 'success',// закрыт
        ticket: null,
        document: 'https://labrador.ru/ipb/uploads/monthly_2016_01/569e155e4abd5_.jpg.d633673a1a0645f52bddb91299e26d99.jpg',
        comment: '',
    }],

    products: [{
        productId: 1,
        title: "Амортизатор для а/м ВАЗ 2101-07 зад. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762699.jpg",
        barcode: '7930075582206',
        brand: 'ProДеталь',
        articule: 'DPAVP2101M',
        productTyle: 'Амортизатор',
        code: '762699',
        actions: [{
            id: 2,
            bonus: 50,
        }]
    },{
        productId: 3,
        title: "Амортизатор для а/м ВАЗ 2101-07 перед. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762703.jpg",
        barcode: '7930075582213',
        brand: 'ProДеталь',
        articule: 'DPAVZ2101M',
        productTyle: 'Амортизатор',
        code: '762703',
        actions: [{
            id: 2,
            bonus: 50,
        }]
    },{
        productId: 5,
        title: "Амортизатор для а/м ВАЗ 2108, 2115 зад. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762704.jpg",
        barcode: '7930075582220',
        brand: 'ProДеталь',
        articule: 'DPAVZ2108M',
        productTyle: 'Амортизатор',
        code: '762704',
        actions: [{
            id: 2,
            bonus: 70,
        }]
    },{
        productId: 7,
        title: "Амортизатор для а/м ВАЗ 2110-12, 1118 зад. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762705.jpg",
        barcode: '7930075582237',
        brand: 'ProДеталь',
        articule: 'DPAVZ2110M',
        productTyle: 'Амортизатор',
        code: '762705',
        actions: [{
            id: 2,
            bonus: 70,
        }]
    },{
        productId: 10,
        title: "Амортизатор для а/м ГАЗ 3302 перед./зад. NEXT зад. (газовый) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762726.jpg",
        barcode: '7930075582367',
        brand: 'ProДеталь',
        articule: 'DPAGZP3302G',
        productTyle: 'Амортизатор',
        code: '762726',
        actions: [{
            id: 2,
            bonus: 60,
        }]
    },{
        productId: 11,
        title: "Амортизатор для а/м ГАЗ 3302 перед./зад. NEXT зад. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762727.jpg",
        barcode: '7930075582350',
        brand: 'ProДеталь',
        articule: 'DPAGZP3302M',
        productTyle: 'Амортизатор',
        code: '762727',
        actions: [{
            id: 2,
            bonus: 60,
        }]
    },{
        productId: 12,
        title: "Замок зажигания для а/м ВАЗ 2101, ГАЗ 2410, 31029 ''BAST''",
        src: "https://b2motor.ru/upload/shop_image/763330.jpg",
        barcode: '7930075583357',
        brand: 'BAST',
        articule: 'BC-302Z',
        productTyle: 'Замок зажигания',
        code: '763330',
        actions: [{
            id: 2,
            bonus: 20,
        }]
    },{
        productId: 30,
        title: "Колодка тормозная задняя для а/м ГАЗ 3302, 2217, NEXT(к-кт 4шт.) ''BAST''",
        bonus: 150,
        src: "https://b2motor.ru/upload/shop_image/763369.jpg",
        barcode: '7930075583753',
        brand: 'BAST',
        articule: 'BC-218C',
        productTyle: 'Колодка тормозная задняя',
        code: '763369',
        actions: [{
            id: 1,
            bonus: 50,
        }]
    },{
        productId: 29,
        title: "Очиститель тормозов , механизмов сцепления ''PRO'' (520 мл) аэроз.",
        src: "https://b2motor.ru/upload/shop_image/103160.jpg",
        barcode: '7930086752223',
        brand: 'ProДеталь',
        articule: 'DPOTS520ML',
        productTyle: 'Очиститель тормозов',
        code: '103160',
        actions: [{
            id: 2,
            bonus: 10,
        },{
            id: 3,
            quantity: 10,
            bonus: 150,
        }]
    },{
        productId: 31,
        title: "Колодка тормозная передняя для а/м УАЗ 31519 Хантер, 3160 Патриот (к-кт 4 шт) ''BAST''",
        src: "https://b2motor.ru/upload/shop_image/763382.jpg",
        barcode: '7930075583890',
        brand: 'BAST',
        articule: 'BC-211C',
        productTyle: 'Колодка тормозная передняя',
        code: '763382',
        actions: [{
            id: 1,
            bonus: 50,
        }]
    },{
        productId: 35,
        title: "Патрубок радиатора для а/м ГАЗ 3302 дв.Chrysler (к-кт 5шт.) ''ProДеталь''",
        src: "https://b2motor.ru/upload/shop_image/758242.jpg",
        barcode: '7930075580479',
        brand: 'ProДеталь',
        articule: 'DPPR330288',
        productTyle: 'Патрубок радиатора',
        code: '758242',
        actions: [{
            id: 2,
            bonus: 20,
        }]
    },{
        productId: 37,
        title: "Подушка двигателя для а/м ВАЗ 2108 передняя в сборе ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/764106.jpg",
        barcode: '7930086754470',
        brand: 'ProДеталь',
        articule: 'DP5102',
        productTyle: 'Подушка двигателя',
        code: '764106',
        actions: [{
            id: 2,
            bonus: 40,
        }]
    },{
        productId: 38,
        title: "Подушка двигателя для а/м ВАЗ 2110 задняя в сборе ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/764108.jpg",
        barcode: '7930086754487',
        brand: 'ProДеталь',
        articule: 'DP5104',
        productTyle: 'Подушка двигателя',
        code: '764108',
        actions: [{
            id: 2,
            bonus: 40,
        }]
    },{
        productId: 20,
        title: "Промывка двигателя 5 мин. ''PRO'' (300 мл.)",
        src: "https://b2motor.ru/upload/shop_image/100762.jpg",
        barcode: '7930075582770',
        brand: 'ProДеталь',
        articule: 'DPPD300ML',
        productTyle: 'Промывка двигателя',
        code: '100762',
        actions: [{
            id: 2,
            bonus: 10,
        },{
            id: 3,
            quantity: 10,
            bonus: 150,
        }]
    },{
        productId: 21,
        title: "Размораживатель замков ''PRO'' с силиконом (75 мл) аэроз.",
        src: "https://b2motor.ru/upload/shop_image/99947.jpg",
        barcode: '7930075581841',
        brand: 'ProДеталь',
        articule: 'DPRZSI75ML',
        productTyle: 'Размораживатель',
        code: '099947',
        actions: [{
            id: 2,
            bonus: 10,
        },{
            id: 3,
            quantity: 10,
            bonus: 150,
        }]
    },{
        productId: 23,
        title: "Размораживатель стекол и замков ''PRO'' (520 мл.) аэроз.",
        src: "https://b2motor.ru/upload/shop_image/100128.jpg",
        barcode: '7930075581827',
        brand: 'ProДеталь',
        articule: 'DPRZSI520ML',
        productTyle: 'Размораживатель',
        code: '100128',
        actions: [{
            id: 2,
            bonus: 10,
        },{
            id: 3,
            quantity: 10,
            bonus: 150,
        }]
    },{
        productId: 25,
        title: "Смазка многоцелевая Туда-сюда ''BAST'' (400мл)",
        src: "https://b2motor.ru/upload/resize_cache/iblock/f8d/140_140_1/f8d60865ca5c342527708dfaae17d5a0.jpg",
        barcode: '7930075582855',
        brand: 'BAST',
        articule: 'BCC400S',
        productTyle: 'Смазка',
        code: '101305',
        actions: [{
            id: 2,
            bonus: 10,
        },{
            id: 3,
            quantity: 10,
            bonus: 150,
        }]
    },{
        productId: 27,
        title: "Смазка силиконовая ''PRO'' (400 мл) аэроз.",
        src: "https://b2motor.ru/upload/shop_image/99554.jpg",
        barcode: '7930075581735',
        brand: 'ProДеталь',
        articule: 'DPSS400ML',
        productTyle: 'Смазка',
        code: '099554',
        actions: [{
            id: 1,
            bonus: 10,
        },{
            id: 3,
            quantity: 10,
            bonus: 150,
        }]
    }],

    actions: [{
            actionId: 1,
            actionType: 'bonus',
            title: "Мега-бонусы от BAST",
            description: 'Продавая товары участвующие в акции "Мега-бонусы от BAST" Вы получаете бонусы с каждой проданной единицы товара',
            start: '01.09.2020',
            end: null,
            products: [27, 30, 31]
        },{
            actionId: 2,
            actionType: 'bonus',
            title: "Веселые продажи с ProДеталь",
            description: 'Продавая товары участвующие в акции "Веселые продажи с ProДеталь" Вы получаете бонусы с каждой проданной единицы товара',
            start: '01.09.2020',
            end: null,
            products: [1, 3, 5, 7, 10, 11, 12, 20, 21, 23, 25, 29, 35, 37, 38]
        },{
            actionId: 3,
            actionType: 'quantity',
            title: 'Автохимия в копилку',
            description: 'За продажу 10 балонов автохимии BAST и ProДеталь во время проведения акции, вы получаете бонусы. (в количественный зачет идет любые виды автохимии BAST и ProДеталь, участвующие в акции)',
            start: '01.10.2020',
            end: '31.12.2020',
            step: 10,
            summStep: 150,
            products: [20, 21, 23, 25, 27, 29]
    }],

    productProperty: [{
            id: 1,
            title: 'Бренд',
            alias: 'brand',
            multiple: false,
            values: [{
                id: 'ProДеталь',
                value: 'ProДеталь'
            },{
                id: 'BAST',
                value: 'BAST'
            }]
        },{
            id: 2,
            title: 'Акции',
            alias: 'actions',
            multiple: true,
            values: [{
                id: 1,
                value: 'Мега-бонусы от BAST'
            },{
                id: 2,
                value: 'Веселые продажи с ProДеталь'
            },{
                id: 3,
                value: 'Автохимия в копилку'
            }]
        },{
            id: 3,
            title: 'Тип детали',
            alias: 'productTyle',
            multiple: true,
            values: [{
                id: 'Амортизатор',
                value: 'Амортизатор'
            },{
                id: 'Замок зажигания',
                value: 'Замок зажигания'
            },{
                id: 'Колодка тормозная задняя',
                value: 'Колодка тормозная задняя'
            },{
                id: 'Колодка тормозная передняя',
                value: 'Колодка тормозная передняя'
            },{
                id: 'Очиститель тормозов',
                value: 'Очиститель тормозов'
            },{
                id: 'Патрубок радиатора',
                value: 'Патрубок радиатора'
            },{
                id: 'Подушка двигателя',
                value: 'Подушка двигателя'
            },{
                id: 'Промывка двигателя',
                value: 'Промывка двигателя'
            },{
                id: 'Размораживатель',
                value: 'Размораживатель'
            },{
                id: 'Смазка',
                value: 'Смазка'
            }]
    }],
    basket: [{
        basketId: 1,
        orderId: null,
        productId: 21,
        userId: 3,
        title: "Размораживатель замков ''PRO'' с силиконом (75 мл) аэроз.",
        src: "https://b2motor.ru/upload/shop_image/99947.jpg",
        barcode: '7930075581841',
        brand: 'ProДеталь',
        articule: 'DPRZSI75ML',
        code: '099947',
        actionId: 2,
        quantity: 5,
        bonus: 10,
        total: 50,
        checked: true,
        status: ''
    },{
        basketId: 2,
        orderId: null,
        productId: 5,
        userId: 3,
        title: "Амортизатор для а/м ВАЗ 2108, 2115 зад. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762704.jpg",
        barcode: '7930075582220',
        brand: 'ProДеталь',
        articule: 'DPAVZ2108M',
        code: '762704',
        actionId: 2,
        quantity: 2,
        bonus: 70,
        total: 140,
        checked: true,
        status: ''
    },{
        basketId: 3,
        orderId: 1,
        productId: 11,
        userId: 3,
        title: "Амортизатор для а/м ГАЗ 3302 перед./зад. NEXT зад. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762727.jpg",
        barcode: '7930075582350',
        brand: 'ProДеталь',
        articule: 'DPAGZP3302M',
        code: '762727',
        actionId: 2,
        quantity: 2,
        bonus: 60,
        total: 120,
        checked: true,
        status: 'success'
    },{
        basketId: 4,
        orderId: 2,
        productId: 31,
        userId: 3,
        title: "Колодка тормозная передняя для а/м УАЗ 31519 Хантер, 3160 Патриот (к-кт 4 шт) ''BAST''",
        src: "https://b2motor.ru/upload/shop_image/763382.jpg",
        barcode: '7930075583890',
        brand: 'BAST',
        articule: 'BC-211C',
        code: '763382',
        actionId: 1,
        quantity: 5,
        bonus: 50,
        total: 250,
        checked: true,
        status: 'success'
    },{
        basketId: 5,
        orderId: 8,
        productId: 20,
        userId: 3,
        title: "Промывка двигателя 5 мин. ''PRO'' (300 мл.)",
        src: "https://b2motor.ru/upload/shop_image/100762.jpg",
        barcode: '7930075582770',
        brand: 'ProДеталь',
        articule: 'DPPD300ML',
        code: '100762',
        actionId: 2,
        quantity: 2,
        bonus: 10,
        total: 20,
        checked: true,
        status: 'denied'
    },{
        basketId: 6,
        orderId: 8,
        productId: 21,
        userId: 3,
        title: "Размораживатель замков ''PRO'' с силиконом (75 мл) аэроз.",
        src: "https://b2motor.ru/upload/shop_image/99947.jpg",
        barcode: '7930075581841',
        brand: 'ProДеталь',
        articule: 'DPRZSI75ML',
        code: '099947',
        actionId: 2,
        quantity: 5,
        bonus: 10,
        total: 50,
        checked: true,
        status: 'success'
    },{
        basketId: 7,
        orderId: 12,
        productId: 3,
        userId: 3,
        title: "Амортизатор для а/м ВАЗ 2101-07 перед. (масляный) ''PROдеталь''",
        src: "https://b2motor.ru/upload/shop_image/762703.jpg",
        barcode: '7930075582213',
        brand: 'ProДеталь',
        articule: 'DPAVZ2101M',
        code: '762703',
        actionId: 2,
        quantity: 2,
        bonus: 50,
        total: 100,
        checked: true,
        status: ''
    },{
        basketId: 8,
        orderId: null,
        productId: 20,
        userId: 3,
        title: "Промывка двигателя 5 мин. ''PRO'' (300 мл.)",
        src: "https://b2motor.ru/upload/shop_image/100762.jpg",
        barcode: '7930075582770',
        brand: 'ProДеталь',
        articule: 'DPPD300ML',
        code: '100762',
        actionId: 2,
        quantity: 6,
        bonus: 10,
        total: 60,
        checked: true,
        status: ''
    }],

    payments: [{
        id: 1,
        data: '04.11.2020',
        status: 'denied',
        total: 500,
        comment: 'Не выбран способ выплаты в профиле.',
        ticket: null
    }]
}

const mutations = {
    setTicket (state, {ticket, message}) {
        const date = new Date()
        state.tickets.unshift({
            id: ticket.id,
            title: ticket.title,
            type: ticket.type,
            number: ticket.number,
            status: 'notanswered',
            data: `${date.getHours()}:${date.getMinutes()} ${String(date.getDate()).length === 1 ? '0' + date.getDate(): date.getDate()}.${String(date.getMonth()).length === 1 ? '0' + date.getMonth(): date.getMonth()}.${date.getFullYear()}`,
            messages: [{
                id: message.messageId,
                data: `${String(date.getDate()).length === 1 ? '0' + date.getDate(): date.getDate()}.${String(date.getMonth()).length === 1 ? '0' + date.getMonth(): date.getMonth()}.${date.getFullYear()}`,
                type: message.type,
                text: message.text,
                name: null,
                time: `${date.getHours()}:${date.getMinutes()}`,
                status: message.status
            }]
        })
    },
    setMessage (state, {ticketId, message}) {
        const date = new Date()
        // const tickets = [...state.tickets]
        const search = state.tickets.find(x => parseInt(x.id, 10) === parseInt(ticketId, 10))
        if (search) {
            search.messages.push({
                id: message.messageId,
                data: `${String(date.getDate()).length === 1 ? '0' + date.getDate(): date.getDate()}.${String(date.getMonth()).length === 1 ? '0' + date.getMonth(): date.getMonth()}.${date.getFullYear()}`,
                type: message.type,
                text: message.text,
                name: null,
                time: `${date.getHours()}:${date.getMinutes()}`,
                status: message.status
            })
        }
    },
    setMessageId (state, messageId) {
        state.messageId = messageId
    },
    setBasketUpdateQuantity (state, { basketId, quantity }) {
        const item = state.basket.find(x => String(x.basketId) === String(basketId))
        quantity = item.quantity + quantity
        if (quantity < 1) {
            quantity = 1
        }
        Object.assign(item, {...item, quantity: quantity, total: item.bonus * quantity})
    },
    setBasketUpdateChecked (state, items) {
        if ((items || []).length > 0) {
            items.forEach(item => {
                const sItem = state.basket.find(x => String(x.basketId) === String(item.basketId))
                Object.assign(sItem, {...sItem, checked: item.checked})
            })
        }
    },
    setBasketDelete (state, basketIds) {
        if ((basketIds || []).length === 0) return false

        basketIds.forEach(basketId => {
            let basket = state.basket
            const sIndex = state.basket.findIndex(x => String(x.basketId) === String(basketId))
            if (sIndex !== -1) {
                basket.splice(sIndex, 1)
            }
            state.basket = basket
        })

    },
    setBasketItemAction (state, { actionId, quantity, bonus }) {
        // ищем акцию
        let action = state.actions.find(a => parseInt(a.actionId, 10) === parseInt(actionId, 10))
        let title = action ? action.title : `Акция ${actionId}`

        // ищем айдишник
        const basket = [...state.basket]
        const basketId = (basket || []).length === 0 ? 1 : (basket.map(x => x.basketId).sort((a, b) => b - a))[0] + 1

        state.basket.push({
            basketId,
            orderId: null,
            productId: null,
            userId: 3,
            title,
            src: "https://socialpriluzie.rkomi.ru/system/attachments/uploads/000/034/720/original/aktsiya.png",
            barcode: '',
            brand: '',
            articule: '',
            code: '',
            actionId,
            quantity,
            bonus,
            total: quantity * bonus,
            checked: true,
            status: ''
        })
    },
    setBasketItem (state, { productId, userId, title, src, barcode, brand, articule, code, actionId, quantity, bonus }) {
        // ищем айдишник
        const basket = [...state.basket]
        const basketId = (basket || []).length === 0 ? 1 : (basket.map(x => x.basketId).sort((a, b) => b - a))[0] + 1

        state.basket.push({
            basketId,
            orderId: null,
            productId: productId || null,
            userId: userId || 3,
            title: title || 'Товар' ,
            src: src || null,
            barcode: barcode || '',
            brand: brand || '',
            articule: articule || '',
            code: code || '',
            actionId: actionId || null,
            quantity: quantity,
            bonus: bonus,
            total: quantity * bonus,
            checked: true,
            status: ''
        })
    },

    setApplication (state, { total, file, basketIds }) {
        if ((basketIds || []).length === 0) return false

        const applications = [...state.applicationList]
        const applicationId = (applications || []).length === 0 ? 1 : (applications.map(x => x.id).sort((a, b) => b - a))[0] + 1
        const data = new Date()

        console.log('applicationId', applicationId)
        const application = {
            id: applicationId, 
            data: `${data.getDate()}.${data.getMonth()}.${data.getFullYear()}`,
            bonusTotal: total, 
            bonusSuccess: 0,
            status: 'sent',
            ticket: null,
            document: file,
            comment: '',
        }

        state.applicationList = [...state.applicationList, application]
        basketIds.forEach(basketId => {
            const sItem = state.basket.find(x => String(x.basketId) === String(basketId))
            Object.assign(sItem, {...sItem, orderId: applicationId})
        })
        return applicationId
    },

    setPayment (state, total) {
        if (!total && isNaN(parseInt(total, 10))) {
            return false
        }
        const payments = [...state.payments]
        const id = (payments || []).length === 0 ? 1 : (payments.map(x => x.id).sort((a, b) => b - a))[0] + 1
        const data = new Date()

        const payment = {
            id,
            data: `${data.getDate()}.${data.getMonth()}.${data.getFullYear()}`,
            status: 'booked',
            total,
            comment: '',
            ticket: null
        }
        state.payments = [...state.payments, payment]
    }

}

export default {
    namespaced: true,
    state,
    getters: {},
    actions: {},
    mutations
}