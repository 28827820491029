import Framework7 from 'framework7/framework7.esm.bundle.js'
import store from '../store'
const baseURL = `https://b2motor.ru/api/delivery`
// const baseURL = `http://vus.b2/api`

Framework7.request.setup({
  crossDomain: true,
  dataType: 'json',
  timeout: 10000
});

let chekRefreshToken = false
function queryPromise(url, data = {}, method = 'GET', headers = {}) {
  return new Promise((resolve, reject) => {
    if (!url) {
      return resolve(new Error('unspecified url'))
    }
    if (store.state.auth.isUserLoggedIn) {
      headers['Authorization'] = `Bearer ${store.state.auth.accessToken}`
    }

    Framework7.request({
      url: baseURL + url,
      method,
      data,
      headers,
      success: (resData, status, xhr) => {
        resolve(resData)
      },
      error: async (xhr, status) => {
        try {
          if (status === 0) {
            return reject(new Error('Неверный ответ сервера, обратитесь к администратору'))
          }
          if (status !== 401) {
            return reject(xhr)
          }

          if (chekRefreshToken) {
            await store.dispatch('auth/setLogaut')
            console.log('chekRefreshToken=true')
            return reject('Ошибка авторизации')
          }
          chekRefreshToken = true

          await store.dispatch('auth/refreshtoken')
          chekRefreshToken = false
          const dataNew = await queryPromise(url, data, method, headers)
          return resolve(dataNew)
        } catch (err) {
          return reject(err)
        }
      }
    })
  })
}

async function postPromise (url, data) {
 try {
   return await queryPromise(url, data, 'POST')
 } catch (err) {
   throw err
 }
}

async function getPromise (url, data) {
  try {
    return await queryPromise(url, data)
  } catch (err) {
    throw err
  }
}

export default {
  get: getPromise,
  post: postPromise,
  query: queryPromise
}
