<template>
  <f7-app :params="f7params" class="color-theme-red">

  <!-- Left panel with cover effect-->
  <f7-panel left cover>
    <f7-view>
      <f7-page bg-color="white">
        <f7-navbar>
          <f7-row no-gap>
            <f7-col width="33">
              <i class="f7-icons" style="padding: 10px; font-size: 40px;">person</i>
            </f7-col>
            <f7-col width="66" style="line-height: 1em; padding: 14px 10px; font-size: 16px;">Иванушков <br> Александр</f7-col>
          </f7-row>
        </f7-navbar>
        <f7-list class="left-menu-list">
          <f7-list-item panel-close view=".view-main" reload-current title="Товары" link="/">
            <f7-icon slot="media" f7="folder"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="Акции" link="/actions/">
            <f7-icon slot="media" f7="percent"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="Мои продажи" link="/applications/">
            <f7-icon slot="media" f7="archivebox"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="Мои баллы" link="/bonusinfo/">
            <f7-icon slot="media" f7="bold"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="Поддержка" link="/support/">
            <f7-icon v-if="this.$store.state.support.isUnreadTicket == false" slot="media" f7="envelope"></f7-icon>
            <f7-icon v-else slot="media" f7="envelope_badge"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="В помощь продавцу" link="#">
            <f7-icon slot="media" f7="info_circle"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="Сбросить" link="#" @click="clearStorage">
            <f7-icon slot="media" f7="arrow_2_circlepath_circle"></f7-icon>
          </f7-list-item>
          <f7-list-item panel-close view=".view-main" reload-current title="Выход" link="#" @click="logout">
            <f7-icon slot="media" f7="xmark_circle"></f7-icon>
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Right Panel"></f7-navbar>
        <f7-block>Right panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


    <!-- Popup -->
    <f7-popup id="my-popup">
      <f7-view>
        <f7-page>
          <f7-navbar title="Popup">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <p>Popup content goes here.</p>
          </f7-block>
        </f7-page>
      </f7-view>
    </f7-popup>

    <f7-login-screen id="my-login-screen">
      <f7-view>
        <f7-page login-screen>
          <f7-login-screen-title>Login</f7-login-screen-title>
          <f7-list form>
            <f7-list-input
              type="text"
              name="username"
              placeholder="Your username"
              :value="username"
              @input="username = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              type="password"
              name="password"
              placeholder="Your password"
              :value="password"
              @input="password = $event.target.value"
            ></f7-list-input>
          <f7-list-input
                  label="Номер телефона"
                  type="text"
                  placeholder="Номер телефона"
                  :value="phone"
                  @input="phone = $event.target.value"
                  error-message="неверный формат (89109502030)"
                  required
                  validate
                  pattern="[7-8]{1}[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}"
                  clear-button
          ></f7-list-input>


          </f7-list>
          <f7-list>
            <f7-list-button title="Sign In" @click="alertLoginData"></f7-list-button>
            <f7-block-footer>
              Some text about login information.<br>Click "Sign In" to close Login Screen
            </f7-block-footer>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-login-screen>
  </f7-app>
</template>
<script>
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';

  export default {
    computed: {
      error () {
        return this.$store.state.error || null
      },
    message () {
      return this.$store.state.message || null
    }
    },
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          id: 'innst.statuspro', // App bundle ID
          name: 'StatusPro', // App name
          theme: 'auto', // Automatic theme detection

          // App routes
          routes: routes,

          // Input settings
          input: {
            scrollIntoViewOnFocus: Device.cordova && !Device.electron,
            scrollIntoViewCentered: Device.cordova && !Device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
          },
          lazy: {
            threshold: 50,
            sequential: false,
          }
        },
        // Login screen data
        username: '',
        password: '',
        phone: '',
      }
    },
    methods: {
      alertLoginData() {
        this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password, () => {
          this.$f7.loginScreen.close()
        })
      },
      async checkDeviceId() {
        try {
          await this.$store.dispatch('setDeviceId')
        } catch (err) {
          this.$store.commit('setError', err)
        }

      },
      clearStorage () {
        localStorage.removeItem('vuex')
        window.location.reload()
      },
      logout () {
        console.log('logout',this.$f7)
        this.$store.commit('auth/setLogaut')
        // ToDo позатирать весь текущий стор

        this.$f7.views.main.router.refreshPage()
      }
    },
    watch: {
    error (text) {
      if (text) {
        console.log('watch-error', text)

        this.$f7.notification.create({
          // icon: '<i class="icon demo-icon">7</i>',
          title: 'StatusPro',
          // titleRightText: 'now',
          // subtitle: 'Notification with close on click',
          text,
          closeTimeout: 3000,
          closeOnClick: true,
        }).open()



        this.$store.commit('setError', null)
        return true
      }
    },
    message (val) {
      if (val) {
        console.log('watch-message', val)
        this.$store.commit('setMessage', null)
        return true
      }
    }
    },
    mounted() {
      this.$store.commit('setError', null)
      this.$store.commit('setMessage', null)
      this.$f7ready(async (f7) => {
        // Init cordova APIs (see cordova-app.js)
        if (Device.cordova) {
          cordovaApp.init(f7);
        }
        // убираем флаг загрузки
        await this.$store.dispatch('setLoading', false)

        // сбрасываем флаг ошибки отсутствия соединения
        await this.$store.dispatch('setNoConnection', false)

        // создает идентификатор устройства (необходим для пушей)
        await this.checkDeviceId()

        // загружаем стартовые данные с сервера
        await this.$store.dispatch('setStartData')

        // событие готовности телефона
        document.addEventListener("deviceready", () => {
          // alert('cordova1-'+self.$f7.device.cordova + '2')
          // alert(navigator.connection.type)
          // cordovaApp.init(f7);

          let backButtonTimestamp = new Date().getTime()
          // управление кнопкой "назад"
          document.addEventListener("backbutton", () => {
            if (new Date().getTime() - backButtonTimestamp < 250) {
              backButtonTimestamp = new Date().getTime()
              if (navigator.app && navigator.app.exitApp) {
                navigator.app.exitApp()
              }
              return true
            }
            backButtonTimestamp = new Date().getTime()
            self.$f7.views.main.router.back()
          }, false)
        })
      })
    }
  }
</script>

<style scoped>
  .left-menu-list {margin-top: 10px}
</style>
