<template>
  <f7-page 
    name="BonusInfo" 
    bg-color="white" 
    ptr 
    :ptr-preloader="false" 
    @ptr:refresh="refrashPage"
    @page:reinit="reinitPage">
    <!-- Top Navbar -->
    <f7-navbar>
        <f7-nav-left>
            <f7-link icon-ios="material:menu" icon-md="material:menu" panel-open="left"></f7-link>
        </f7-nav-left>
        <f7-nav-title sliding>Мои баллы</f7-nav-title>
        <f7-nav-right>
          <f7-link icon-only href="/cart/">
            <f7-icon f7="cart_fill" class="icon f7-icons">
              <f7-badge v-if="$store.state.basket.count > 0" color="lightblue">{{ $store.state.basket.count }}</f7-badge>
            </f7-icon>
          </f7-link>
        </f7-nav-right>
    </f7-navbar>

    <f7-block-title v-show="loading" class="skeleton-text skeleton-effect-blink">Loremipsum ipsumdolor</f7-block-title>
    <f7-list v-show="loading" class="skeleton-text skeleton-effect-blink">
      <f7-list-item title="Loremipsum dolor" after="000000"></f7-list-item>
      <f7-list-item title="Loremips" after="0000"></f7-list-item>
      <f7-list-item title="Lorem ipsum dolor" after="0000"></f7-list-item>
    </f7-list>

    <f7-block-title v-show="!loading">Общая информация</f7-block-title>
    <f7-list v-show="!loading">
      <f7-list-item title="Партнерский код" :after="`${partnerCode}`"></f7-list-item>
      <f7-list-item title="Всего начислено" :after="`${totalSuccess} ${transformText(totalSuccess)}`"></f7-list-item>
      <f7-list-item title="Всего выплачено" :after="`${totalPayments} ${transformText(totalPayments)}`"></f7-list-item>
      <f7-list-item title="На рассмотрении" :after="`${totalSubmitted} ${transformText(totalSubmitted)}`"></f7-list-item>
      <f7-list-item title="Доступно" :after="`${availableBonuses} ${transformText(availableBonuses)}`"></f7-list-item>
    </f7-list>
    <f7-block-title v-show="!loading">Справочная информация</f7-block-title>
    <f7-list v-show="!loading">
      <f7-list-item v-for="item in conditions" :key="item.id" :title="item.title" :link="`/reference/${item.id}/`"></f7-list-item>
    </f7-list>
    <f7-block-title v-show="!loading">Статистика</f7-block-title>
    <f7-list v-show="!loading">
      <f7-list-item title="Выплаты" link="/payments/"></f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    name: "BonusInfo",
    computed: {
      totalSuccess () {
        return this.$store.state.bonus.totalSuccess || 0
      },
      totalSubmitted () {
        return this.$store.state.bonus.totalSubmitted || 0
      },
      totalPayments () {
        return this.$store.state.bonus.totalPayments || 0
      },
      availableBonuses () {
        return this.$store.state.bonus.availableBonuses || 0
      },
      partnerCode () {
        return this.$store.state.auth.partnerCode || null
      },
    },
    data () {
      return {
        loading: false,
        conditions: []
      }
    },
    methods: {
      transformText (quantity) {
        if (String(quantity) === '1') {
          return 'бонус'
        } else if (String(quantity) > '1' && String(quantity) < '5') {
          return 'бонуса'
        } else {
          return 'бонусов'
        }
      },
      async getData () {
        try {
          await Promise.all([
            await this.$store.dispatch('bonus/getBalans'),
            this.conditions = await this.$store.dispatch('bonus/conditionList')
          ])
        } catch (err) {
          this.$store.commit('setError', err)
        }
      },
      async refrashPage(done) {
        this.loading = true
        done()
        this.clear()
        await this.getData()
        this.loading = false
      },
      async reinitPage () {
        this.loading = true
        this.clear()
        await this.getData()
        this.loading = false
      },
      clear () {
        this.$store.commit('bonus/clear')
      }
    },
    async mounted () {
      this.loading = true
      await this.getData()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>