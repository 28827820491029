<template>
  <f7-page name="application" class="page-action" bg-color="white">
    <!-- Top Navbar -->
    <f7-navbar back-link="Back">
      <f7-nav-title v-if="id">Заявка {{ id }}/{{ data }}</f7-nav-title>
      <f7-nav-title v-else></f7-nav-title>
    </f7-navbar>
    
    <!-- <f7-block-title v-show="!loading" medium>Заявка {{id}} от {{data}}</f7-block-title> -->
    <f7-list v-show="!loading"  accordion-list>
      <f7-list-item title="Статус">
        <div v-if="status === 'sent'" slot="after" class="text-color-lightblue application-status">Отправлено</div>
        <div v-if="status === 'inwork'" slot="after" class="text-color-yellow application-status">На рассмотрении</div>
        <div v-if="status === 'success'" slot="after" class="text-color-green application-status">Закрыто</div>
      </f7-list-item>
      <f7-list-item title="Всего" :after="`${bonusTotal} бонусов`"></f7-list-item>
      <f7-list-item title="Одобрено" :after="`${bonusSuccess} бонусов`"></f7-list-item>

      <f7-list-item :accordion-item="document !== null && String(document).trim().length > 0" title="Подтверждение">
        <f7-accordion-content v-if="document !== null && String(document).trim().length > 0">
          <f7-card no-shadow>
            <f7-card-content>
              <div class="card-images">
                <img :src="`${document}`" />
              </div>
              <f7-skeleton-block v-show="loading" style="width: 250px; height: 190px;margin: 0 auto 15px"></f7-skeleton-block>
            </f7-card-content>
          </f7-card>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item :accordion-item="comment !== null && String(comment).trim().length > 0" title="Комментарий">
        <div v-if="!comment" slot="after">нет</div>
        <f7-accordion-content v-if="comment !== null && String(comment).trim().length > 0">
          <f7-card no-shadow>
            <f7-card-content>
              <p>{{ comment }}</p>
            </f7-card-content>
          </f7-card>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-block-title  v-show="!loading && products.length > 0" medium style="font-size: 18px">Список товаров</f7-block-title>
    <f7-list v-show="!loading && products.length > 0" media-list>
      <f7-list-item v-for="item in products" :key="item.productId" :header="item.title">
        <div slot="after" class="text-color-primary">{{ item.total }} Баллов</div>
        <img slot="media" :data-src="item.src" class="lazy lazy-fade-in" width="80" height="60" />
        <div v-if="item.status === 'success'" slot="title" class="text-color-green application-status">Одобрено</div>
        <div v-if="item.status === 'denied'" slot="title" class="text-color-red application-status">Отклонено</div>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    name: "Application",
    data () {
      return {
        loading: false,
        id: null,
        data: null,
        status: 'sent',


        bonusTotal: 0,
        bonusSuccess: 0,
        document: 'https://labrador.ru/ipb/uploads/monthly_2016_01/569e155e4abd5_.jpg.d633673a1a0645f52bddb91299e26d99.jpg',
        comment: '',



        products: []
      }
    },
    methods: {
      async getData () {
        try {
          if (!this.$f7route.params.hasOwnProperty('applicationId')) {
            throw new Error('data error')
          }
          this.loading = true
          const { id, data, status, bonusTotal, bonusSuccess, document, comment, products } = await this.$store.dispatch('applications/getData', this.$f7route.params.applicationId)
          this.id = id || null,
          this.data = data || null,
          this.status = status || ''
          this.bonusTotal = bonusTotal || 0
          this.bonusSuccess = bonusSuccess || 0
          this.document = document || null
          this.comment = comment || null,

          this.products = products || []
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.commit('setError', err)
        }
      }
    },
    async mounted () {
      await this.getData()
      this.$f7.lazy.create('.page-action.page-current')
    }
  }
</script>

<style scoped>
  .card-images {max-width: 250px;text-align: center;margin: 15px auto; position: relative;}
  .card-images img { width: 100%; height: auto}
  .application-header {
    font-size: 18px;
  }
  .application-status {
    font-size: 14px;
    font-weight: none;
  }
</style>
