<template>
  <f7-page name="Login" bg-color="white" no-swipeback>
    <f7-navbar title="StatusPro"></f7-navbar>
    <f7-card>
      <f7-card-content >
        <f7-login-screen-title :style="{'margin-bottom': '25px'}">Вход</f7-login-screen-title>

        <f7-list  v-if="loading" form class="skeleton-text skeleton-effect-blink">
          <f7-list-input placeholder="phone number">
            <f7-icon f7="phone_down_circle_fill" slot="media"></f7-icon>
          </f7-list-input>
          <f7-list-input placeholder="password">
            <f7-icon material="ac_unit" slot="media"></f7-icon>
          </f7-list-input>
        </f7-list>
        <f7-list  v-if="!loading" form>
          <f7-list-input
                  label="Номер телефона"
                  type="text"
                  placeholder="Номер телефона"
                  :value="phone"
                  @input="phone = $event.target.value"
                  error-message="неверный формат (89109502030)"
                  required
                  validate
                  pattern="[7-8]{1}[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}"
                  clear-button
          >
            <f7-icon f7="phone_down_circle_fill" slot="media"></f7-icon>
          </f7-list-input>
          <f7-list-input
                  label="Пароль"
                  type="password"
                  placeholder="Пароль"
                  :value="password"
                  @input="password = $event.target.value"
                  error-message="неверный формат"
                  required
                  validate
                  pattern="[0-9]{4}"
                  clear-button
          >
            <f7-icon material="ac_unit" slot="media"></f7-icon>
          </f7-list-input>
        </f7-list>
        <f7-button large raised fill :style="{'margin-top': '25px'}" color="red" @click="signIn">Авторизация</f7-button>
        <f7-block-footer>После заключения договора телефон куьера фиксирпуется в системе и выдается пароль. Работа в приложении без заключении договора невозможна</f7-block-footer>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
  export default {
    data () {
      return {
        loading: false,
        phone: '89159467546',
        password: '',
        message: ''
      }
    },
    methods: {
      validate () {
        if (this.phone.replace(/[^+\d]/g, '').length < 10) {
          return false;
        }
        return true
      },
      async signIn () {
        try {
          this.phone = '9103856962' //'9302767860'
          this.password = '123456'
          if (this.loading) {
            return false
          }
          this.message = ''
          // if (!this.validate()) {
          //   return false
          // }
          this.loading = true
          // await this.$store.dispatch('auth/login', {
          //   login: this.phone,
          //   password: this.password,
          // })

          this.$store.commit('auth/setLoginTest')

        // this.$f7.views.main.router.refreshPage()
console.log('hgfdcxszesxrdctfvbhnjmkmjnhb')
          this.loading = false
          this.$f7router.clearPreviousHistory()
          // this.$f7router.navigate('/')
          this.$f7router.refreshPage()
        } catch (err) {
          this.loading = false
          await this.$store.dispatch('auth/setLogaut')
          this.$store.commit('setError', `${err}`)
          this.$f7router.refreshPage()
        }
      }
    }
  }
</script>

<style scoped>
</style>
