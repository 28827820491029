<template>
    <f7-page 
      name="applications"
      class="page-applications"
      bg-color="white" 
      ptr 
      :ptr-preloader="false" 
      @ptr:refresh="refrashPage"
      infinite
      :infinite-preloader="allowInfinite"
      @infinite="infinitePage"
      @page:reinit="reinitPage">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-ios="material:menu" icon-md="material:menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title sliding>Мои продажи</f7-nav-title>
            <f7-nav-right>
              <f7-link icon-only href="/cart/">
                <f7-icon f7="cart_fill" class="icon f7-icons">
                  <f7-badge v-if="$store.state.basket.count > 0" color="lightblue">{{ $store.state.basket.count }}</f7-badge>
                </f7-icon>
              </f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-toolbar :inner="false" top>
          <f7-row no-gap class="top-statistic">
            <f7-col><div>Всего</div><div class="top-statistic-bonus">{{ bonusTotal }} Бонусов</div></f7-col>
            <f7-col><div>Одобренно</div><div class="top-statistic-bonus">{{ bonusSuccess }} Бонусов</div></f7-col>
            <f7-col><div>На проверке</div><div class="top-statistic-bonus">{{ bonusSubmitted }} Бонусов</div></f7-col>
          </f7-row>
        </f7-toolbar>

        <!-- Page content-->
        <f7-list v-show="loading" media-list class="skeleton-text skeleton-effect-blink">
          <f7-list-item v-for="n in 3" header="Loremipsum " after="0000 Lorem" title="000000 12.12.2020" :key="n"></f7-list-item>
        </f7-list>

        <f7-block-title v-if="!loading && items.length === 0" medium style="font-size: 18px; text-align: center;">У Вас еще не было продаж</f7-block-title>

        <f7-list v-if="!loading && items.length > 0" media-list >
            <f7-list-item v-for="item in items" :link="`/applications/${item.id}/`" :title="`Заявка ${item.id} от ${item.data}`" :subtitle="`бонусы: ${item.bonusTotal}/${item.bonusSuccess}`" :key="item.id">
                <div v-if="item.status === 'sent'" slot="header" class="text-color-lightblue">отправлено</div>
                <div v-if="item.status === 'success'" slot="header" class="text-color-green">обработано</div>
                <div v-if="item.status === 'inwork'" slot="header" class="text-color-yellow">на рассмотрении</div>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>
  export default {
    name: "actions",
    data () {
      return {
        loading: false,
        items: [],
        totalItems: 0,
        order: 'id',
        by: 'asc',
        page: 1,
        allowInfinite: false,

        bonusTotal: 0,
        bonusSuccess: 0,
        bonusSubmitted: 0,
      }
    },
    methods: {
      async getData () {
        try {
          const { items, totalItems } = await this.$store.dispatch('applications/getList', {
            page: this.page,
            order: this.order,
            by: this.by
          })
          if ((items || []).length > 0) {
            this.items = [...this.items, ...items]
          }
          this.totalItems = totalItems
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.commit('setError', err)
        }
      },
      async getStatistics () {
        try {
          const { bonusTotal, bonusSuccess, bonusSubmitted } = await this.$store.dispatch('applications/getStatistics')
          this.bonusTotal = bonusTotal || 0
          this.bonusSuccess = bonusSuccess || 0
          this.bonusSubmitted = bonusSubmitted || 0
        } catch (err) {
          this.bonusTotal = 0
          this.bonusSuccess = 0
          this.bonusSubmitted = 0
          this.$store.commit('setError', err)
        }
      },
      async infinitePage () {
        if (this.allowInfinite) 
          return false

        if ((this.items || []).length >= this.totalItems)
          return false

        this.allowInfinite = true
        ++this.page
        await this.getData()
        this.allowInfinite = false
      },
      async refrashPage(done) {
        this.loading = true
        done()
        this.clearItems()
        await this.getData()
        this.loading = false
      },
      async reinitPage () {
        this.loading = true
        this.clearItems()
        await this.getData()
        this.loading = false
      },
      clearItems () {
        this.items = []
        this.page = 1
      }
    },
    async mounted () {
      this.loading = true
      this.clearItems()
      await Promise.all([
        this.getData(),
        this.getStatistics()
      ])
      this.loading = false
    }
  }
</script>

<style scoped>
  .top-statistic {font-size: 12px; line-height: 1em; text-align: center;}
  .top-statistic-bonus {line-height: 1.8em;}
  .block-title{margin-top: 16px;}
  .media-list{margin-top: 16px;}
</style>
