

const state = {
    items: [],
    total: 0,
    count: 0,
    file: null,
    backgroundImage: null
}

const actions = {
    async chekCount ({ state, rootState, commit }) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }
            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {

                    resolve({
                        status: '1',
                        data: {
                            count: (rootState.base.basket.filter(x => x.orderId === null && x.checked === true && x.productId !== null) || []).length
                        },
                        errorCode: '',
                        errorMessage: ''
                    })
                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }

            commit('setCount', data.count)
            return true
        } catch (err) {
            throw err
        }
    },

    async getList ({ state, rootState, commit }) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }

            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {
                    let items = rootState.base.basket.filter(x => x.orderId === null)
                    let total = 0
                    if ((items || []).length > 0) {
                        // добавляем баллы за количественные акции последнему активному элементу (это все типо на сервере)
                        const totalActions = creatBasketActionItem([...items.filter(x => x.checked === true)], [...rootState.base.products])

                        // проверяем и удаляем добавочные элементы корзины если их нет
                        let actionItems = rootState.base.basket.filter(x => x.orderId === null && x.productId === null)
                        if ((totalActions || []).length === 0) {
                            actionItems.forEach(actionItem => {
                                commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                            })
                        } else {
                            let isActions = []
                            actionItems.forEach(actionItem => {
                                const sTotalAction = totalActions.find(x => x.id === actionItem.actionId)
                                if (!sTotalAction) {
                                    commit('base/setBasketDelete', [actionItem.basketId], { root: true })

                                } else {
                                    isActions.push(sTotalAction.id)
                                    // если количество не набрано тоже удаляем
                                    if (sTotalAction.total < sTotalAction.quantity) {
                                        commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                    } else {
                                        // тут все удовлетворяет условиям и надо поменять количество и сумму
                                        const actionsQuantity = Math.floor(sTotalAction.total / sTotalAction.quantity)
                                        if (actionItem.quantity !== actionsQuantity) {
                                            // обновляем quantity и total
                                            commit('base/setBasketUpdateQuantity', {  basketId: actionItem.basketId, quantity: actionsQuantity - actionItem.quantity }, { root: true })
                                        }
                                    }
                                }
                            })
                            items = rootState.base.basket.filter(x => x.orderId === null)
                            // далее пробегаем по totalActions и смотрим все ли есть в карзине
                            totalActions.forEach(t => {
                                const sActionId = isActions.find(x => t.actionId === x)
                                if (!sActionId && t.total >= t.quantity) {
                                    const sItem = items.find(x => x.productId === null && x.actionId === t.id)
                                    if (!sItem) {
                                        commit('base/setBasketItemAction', {
                                            actionId: t.id,
                                            quantity: Math.floor(t.total / t.quantity),
                                            bonus: t.bonus
                                        }, { root: true })
                                    }
                                }
                            })
                        }

                        items = rootState.base.basket.filter(x => x.orderId === null)
                        if ((items.filter(x => x.checked === true) || []).length > 0) {
                            total = (items.filter(x => x.checked === true) || []).length === 1 ? items.filter(x => x.checked === true)[0].total : items.filter(x => x.checked === true).reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        }
                        
                    }
                    resolve({
                        status: '1',
                        data: {
                            items,
                            count: (items.filter(x => x.checked === true && x.productId !== null) || []).length,
                            total
                        },
                        errorCode: '',
                        errorMessage: ''
                      })
                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }

            commit('setItems', data.items)
            commit('setCount', data.count)
            commit('setTotal', data.total)
            return true
        } catch (err) {
            throw err
        }
    },
    async deleteElement ({ state, rootState, commit }, basketIds) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }

            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {
                    commit('base/setBasketDelete', basketIds, { root: true })


                    let items = rootState.base.basket.filter(x => x.orderId === null)
                    let total = 0
                    if ((items || []).length > 0) {
                        // добавляем баллы за количественные акции последнему активному элементу (это все типо на сервере)
                        const totalActions = creatBasketActionItem([...items.filter(x => x.checked === true)], [...rootState.base.products])

                        // проверяем и удаляем добавочные элементы корзины если их нет
                        let actionItems = rootState.base.basket.filter(x => x.orderId === null && x.productId === null)
                        if ((totalActions || []).length === 0) {
                            actionItems.forEach(actionItem => {
                                commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                            })
                        } else {
                            let isActions = []
                            actionItems.forEach(actionItem => {
                                const sTotalAction = totalActions.find(x => x.id === actionItem.actionId)
                                if (!sTotalAction) {
                                    commit('base/setBasketDelete', [actionItem.basketId], { root: true })

                                } else {
                                    isActions.push(sTotalAction.id)
                                    // если количество не набрано тоже удаляем
                                    if (sTotalAction.total < sTotalAction.quantity) {
                                        commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                    } else {
                                        // тут все удовлетворяет условиям и надо поменять количество и сумму
                                        const actionsQuantity = Math.floor(sTotalAction.total / sTotalAction.quantity)
                                        if (actionItem.quantity !== actionsQuantity) {
                                            // обновляем quantity и total
                                            commit('base/setBasketUpdateQuantity', {  basketId: actionItem.basketId, quantity: actionsQuantity - actionItem.quantity }, { root: true })
                                        }
                                    }
                                }
                            })
                            items = rootState.base.basket.filter(x => x.orderId === null)
                            // далее пробегаем по totalActions и смотрим все ли есть в карзине
                            totalActions.forEach(t => {
                                const sActionId = isActions.find(x => t.actionId === x)
                                if (!sActionId && t.total >= t.quantity) {
                                    const sItem = items.find(x => x.productId === null && x.actionId === t.id)
                                    if (!sItem) {
                                        commit('base/setBasketItemAction', {
                                            actionId: t.id,
                                            quantity: Math.floor(t.total / t.quantity),
                                            bonus: t.bonus
                                        }, { root: true })
                                    }
                                }
                            })
                        }

                        items = rootState.base.basket.filter(x => x.orderId === null)
                        if ((items.filter(x => x.checked === true) || []).length > 0) {
                            total = (items.filter(x => x.checked === true) || []).length === 1 ? items.filter(x => x.checked === true)[0].total : items.filter(x => x.checked === true).reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        }
                    }
                    resolve({
                        status: '1',
                        data: {
                            items,
                            count: (items.filter(x => x.checked === true && x.productId !== null) || []).length,
                            total
                        },
                        errorCode: '',
                        errorMessage: ''
                      })
                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }

            commit('setItems', data.items)
            commit('setCount', data.count)
            commit('setTotal', data.total)
            return true
        } catch (err) {
            throw err
        }
    },
    async addElement ({ rootState, commit }, productId) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }

            if (!productId) {
                new Error('Товар не найден') 
            }

            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {
                    let basket = rootState.base.basket.find(x => x.orderId === null && x.productId === productId)
                    if (basket) {
                        commit('base/setBasketUpdateQuantity', {  basketId: basket.basketId, quantity: 1 }, { root: true })
                    } else {
                        const products = [...rootState.base.products]
                        const product = products.find(x => parseInt(x.productId, 10) === parseInt(productId, 10))
                        console.log('productproductproduct',productId, product)

                        if (product) {
                            let actionId = null
                            let bonus = 0
                            if ((product.actions || []).length > 0) {
                                const sActions = product.actions.find(x => !x.quantity)
                                console.log('sActionssActions', sActions)
                                if (sActions) {
                                    actionId = sActions.id
                                    bonus = sActions.bonus
                                }
                            }

                            commit('base/setBasketItem', {
                                productId: product.productId, 
                                userId: 3, 
                                title: product.title, 
                                src: product.src, 
                                barcode: product.barcode, 
                                brand: product.brand, 
                                articule: product.articule, 
                                code: product.code, 
                                actionId, 
                                quantity: 1, 
                                bonus
                            },{ root: true })
                        }
                    }
                    resolve({
                        status: '1',
                        data: {
                            count: (rootState.base.basket.filter(x => x.orderId === null && x.checked === true && x.productId !== null) || []).length,
                        },
                        errorCode: '',
                        errorMessage: ''
                      })
                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }
            commit('setCount', data.count)
            return true
        } catch (err) {
            throw err
        }
    },
    async updateItems ({ state, rootState, commit }, { basketId, quantity }) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }

            if (!basketId) {
                throw new Error('Ошибка идентификации эллемента')
            }

            if (!quantity || isNaN(parseInt(quantity, 10))) {
                quantity = 1
            }

            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {

                    commit('base/setBasketUpdateQuantity', {  basketId, quantity }, { root: true })

                    let items = rootState.base.basket.filter(x => x.orderId === null)
                    let total = 0
                    if ((items || []).length > 0) {
                        // добавляем баллы за количественные акции последнему активному элементу (это все типо на сервере)
                        const totalActions = creatBasketActionItem([...items.filter(x => x.checked === true)], [...rootState.base.products])

                        // проверяем и удаляем добавочные элементы корзины если их нет
                        let actionItems = rootState.base.basket.filter(x => x.orderId === null && x.productId === null)
                        if ((totalActions || []).length === 0) {
                            actionItems.forEach(actionItem => {
                                commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                            })
                        } else {
                            let isActions = []
                            actionItems.forEach(actionItem => {
                                const sTotalAction = totalActions.find(x => x.id === actionItem.actionId)
                                if (!sTotalAction) {
                                    commit('base/setBasketDelete', [actionItem.basketId], { root: true })

                                } else {
                                    isActions.push(sTotalAction.id)
                                    // если количество не набрано тоже удаляем
                                    if (sTotalAction.total < sTotalAction.quantity) {
                                        commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                    } else {
                                        // тут все удовлетворяет условиям и надо поменять количество и сумму
                                        const actionsQuantity = Math.floor(sTotalAction.total / sTotalAction.quantity)
                                        if (actionItem.quantity !== actionsQuantity) {
                                            // обновляем quantity и total
                                            commit('base/setBasketUpdateQuantity', {  basketId: actionItem.basketId, quantity: actionsQuantity - actionItem.quantity }, { root: true })
                                        }
                                    }
                                }
                            })
                            items = rootState.base.basket.filter(x => x.orderId === null)
                            // далее пробегаем по totalActions и смотрим все ли есть в карзине
                            totalActions.forEach(t => {
                                const sActionId = isActions.find(x => t.actionId === x)
                                if (!sActionId && t.total >= t.quantity) {
                                    const sItem = items.find(x => x.productId === null && x.actionId === t.id)
                                    if (!sItem) {
                                        commit('base/setBasketItemAction', {
                                            actionId: t.id,
                                            quantity: Math.floor(t.total / t.quantity),
                                            bonus: t.bonus
                                        }, { root: true })
                                    }
                                }
                            })
                        }

                        items = rootState.base.basket.filter(x => x.orderId === null)
                        if ((items.filter(x => x.checked === true) || []).length > 0) {
                            total = (items.filter(x => x.checked === true) || []).length === 1 ? items.filter(x => x.checked === true)[0].total : items.filter(x => x.checked === true).reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        }

                    }
                    const item = rootState.base.basket.find(x => String(x.basketId) === String(basketId))
                    resolve({
                        status: '1',
                        data: {
                            items,
                            count: (items.filter(x => x.checked === true && x.productId !== null) || []).length,
                            total
                        },
                        errorCode: '',
                        errorMessage: ''
                      })
                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }

            commit('setItems', data.items)
            commit('setCount', data.count)
            commit('setTotal', data.total)
            return true
        } catch (err) {
            throw err
        }
    },
    async checkedItem ({ state, rootState, commit },  elements) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }

            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {
                    commit('base/setBasketUpdateChecked', elements, { root: true })
         
                    let items = rootState.base.basket.filter(x => x.orderId === null)
                    let total = 0
                    if ((items || []).length > 0) {
                        // добавляем баллы за количественные акции последнему активному элементу (это все типо на сервере)
                        const totalActions = creatBasketActionItem([...items.filter(x => x.checked === true)], [...rootState.base.products])

                        // проверяем и удаляем добавочные элементы корзины если их нет
                        let actionItems = rootState.base.basket.filter(x => x.orderId === null && x.productId === null)
                        if ((totalActions || []).length === 0) {
                            actionItems.forEach(actionItem => {
                                commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                            })
                        } else {
                            let isActions = []
                            actionItems.forEach(actionItem => {
                                const sTotalAction = totalActions.find(x => x.id === actionItem.actionId)
                                if (!sTotalAction) {
                                    commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                } else {
                                    isActions.push(sTotalAction.id)
                                    // если количество не набрано тоже удаляем
                                    if (sTotalAction.total < sTotalAction.quantity) {
                                        commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                    } else {
                                        // тут все удовлетворяет условиям и надо поменять количество и сумму
                                        const actionsQuantity = Math.floor(sTotalAction.total / sTotalAction.quantity)
                                        if (actionItem.quantity !== actionsQuantity) {
                                            // обновляем quantity и total
                                            commit('base/setBasketUpdateQuantity', {  basketId: actionItem.basketId, quantity: actionsQuantity - actionItem.quantity }, { root: true })
                                        }
                                    }
                                }
                            })
                            items = rootState.base.basket.filter(x => x.orderId === null)
                            // далее пробегаем по totalActions и смотрим все ли есть в карзине
                            totalActions.forEach(t => {
                                const sActionId = isActions.find(x => t.actionId === x)
                                if (!sActionId && t.total >= t.quantity) {
                                    const sItem = items.find(x => x.productId === null && x.actionId === t.id)
                                    if (!sItem) {
                                        commit('base/setBasketItemAction', {
                                            actionId: t.id,
                                            quantity: Math.floor(t.total / t.quantity),
                                            bonus: t.bonus
                                        }, { root: true })
                                    }
                                }
                            })
                        }

                        items = rootState.base.basket.filter(x => x.orderId === null)
                        // items = calculationTotal([...items.filter(x => x.checked === true)], [...rootState.base.products])
                        // total = items.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        if ((items.filter(x => x.checked === true) || []).length > 0) {
                            total = (items.filter(x => x.checked === true) || []).length === 1 ? items.filter(x => x.checked === true)[0].total : items.filter(x => x.checked === true).reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        }
                    }
                    resolve({
                        status: '1',
                        data: {
                            items,
                            count: (items.filter(x => x.checked === true && x.productId !== null) || []).length,
                            total
                        },
                        errorCode: '',
                        errorMessage: ''
                      })

                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }
            
            commit('setItems', data.items)
            commit('setCount', data.count)
            commit('setTotal', data.total)
            return true
        } catch (err) {
            throw err
        }
    },
    async setApplication ({ state, rootState, commit }) {
        try {
            if (rootState.auth.userId === null) {
                new Error('no-auth')
            }

            if ((state.items.filter(x => x.checked === true) || []).length === 0) {
                new Error('Элементы не выбраны')
            }

            if (!state.backgroundImage) {
                new Error('Отсутствует подтверждение')
            }

            const { data, status, errorMessage } = await (() => new Promise(resolve => {
                setTimeout(() => {
                    commit('base/setApplication', {
                        basketIds: state.items.filter(x => x.checked === true).map(x => x.basketId),
                        total: (state.items.filter(x => x.checked === true) || []).length === 1 ? state.items.filter(x => x.checked === true)[0].total : state.items.filter(x => x.checked === true).reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total),
                        file: state.backgroundImage
                    }, { root: true })


                    let items = rootState.base.basket.filter(x => x.orderId === null)
                    let total = 0
                    if ((items || []).length > 0) {
                        // добавляем баллы за количественные акции последнему активному элементу (это все типо на сервере)
                        const totalActions = creatBasketActionItem([...items.filter(x => x.checked === true)], [...rootState.base.products])

                        // проверяем и удаляем добавочные элементы корзины если их нет
                        let actionItems = rootState.base.basket.filter(x => x.orderId === null && x.productId === null)
                        if ((totalActions || []).length === 0) {
                            actionItems.forEach(actionItem => {
                                commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                            })
                        } else {
                            let isActions = []
                            actionItems.forEach(actionItem => {
                                const sTotalAction = totalActions.find(x => x.id === actionItem.actionId)
                                if (!sTotalAction) {
                                    commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                } else {
                                    isActions.push(sTotalAction.id)
                                    // если количество не набрано тоже удаляем
                                    if (sTotalAction.total < sTotalAction.quantity) {
                                        commit('base/setBasketDelete', [actionItem.basketId], { root: true })
                                    } else {
                                        // тут все удовлетворяет условиям и надо поменять количество и сумму
                                        const actionsQuantity = Math.floor(sTotalAction.total / sTotalAction.quantity)
                                        if (actionItem.quantity !== actionsQuantity) {
                                            // обновляем quantity и total
                                            commit('base/setBasketUpdateQuantity', {  basketId: actionItem.basketId, quantity: actionsQuantity - actionItem.quantity }, { root: true })
                                        }
                                    }
                                }
                            })
                            items = rootState.base.basket.filter(x => x.orderId === null)
                            // далее пробегаем по totalActions и смотрим все ли есть в карзине
                            totalActions.forEach(t => {
                                const sActionId = isActions.find(x => t.actionId === x)
                                if (!sActionId && t.total >= t.quantity) {
                                    const sItem = items.find(x => x.productId === null && x.actionId === t.id)
                                    if (!sItem) {
                                        commit('base/setBasketItemAction', {
                                            actionId: t.id,
                                            quantity: Math.floor(t.total / t.quantity),
                                            bonus: t.bonus
                                        }, { root: true })
                                    }
                                }
                            })
                        }

                        items = rootState.base.basket.filter(x => x.orderId === null)
                        // items = calculationTotal([...items.filter(x => x.checked === true)], [...rootState.base.products])
                        // total = items.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        if ((items.filter(x => x.checked === true) || []).length > 0) {
                            total = (items.filter(x => x.checked === true) || []).length === 1 ? items[0].total : items.filter(x => x.checked === true).reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
                        }
                    }

                    resolve({
                        status: '1',
                        data: {
                            items,
                            count: (items.filter(x => x.checked === true && x.productId !== null) || []).length,
                            total,
                            orderId: (rootState.base.applicationList || []).length === 0 ? 1 : (rootState.base.applicationList.map(x => x.id).sort((a, b) => b - a))[0]
                        },
                        errorCode: '',
                        errorMessage: ''
                      })
                }, 500)
            }))()

            // const { data, status, errorMessage } = await api.get('/point/close/', {})
            if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
                throw errorMessage || new Error('uncorrected response')
            }
            
            commit('setItems', data.items)
            commit('setCount', data.count)
            commit('setTotal', data.total)
            commit('setFile', null)
            commit('setBackgroundImage', null)
            return data.orderId
        } catch (err) {
            throw err
        }
    }
}
const mutations = {
    setItems (state, items) {
        items = items && Array.isArray(items) ? items : []
        if (items.length > 0) {
            items.forEach(item => {
                const s = state.items.find(x => x.basketId === item.basketId)
                items.checked = s ? s : true
            })
        }
        state.items = items
    },
    setTotal (state, total) {
        state.total = total
    },
    setCount (state, count) {
        state.count = count
    },
    setFile (state, file) {
        state.file = file
    },
    setBackgroundImage (state, backgroundImage) {
        state.backgroundImage = backgroundImage
    },
    updateItems (state, { basketId, quantity }) {
        if ((state.items || []).length == 0)  return false

        const item = state.items.find(x => String(x.basketId) === String(basketId))
        if (item) {
            Object.assign(item, {...item, quantity})
        }
    },
    setCheckedItem (state, { basketId, checked}) {
        if ((state.items || []).length == 0) return false

        const item = state.items.find(x => String(x.basketId) === String(basketId))
        if (item) {
            Object.assign(item, {...item, checked})
        }
    },
    // setCheckedAllItems (state) {
    //     if ((state.items || []).length == 0) return false

    //     const checked = state.items.filter(x => x.checked === true).length !== state.items.length ? true : false
    //     state.items = state.items.map(item => ({...item, checked}))
    // }
}
const getters = {
    quantity: state => (state.items || []).length
}

function calculationTotal(items, products) {
    let total = 0
    let totalActions = []
    console.log('aaaaaaaaaaaaaaa')
    if ((items || []).length > 0) {

        items.forEach(item => {
            const product = products.find(x => parseInt(x.productId, 10) === parseInt(item.productId, 10))
            if (product) {
                if ((product.actions || []).length > 0) {
                    const sAction = product.actions.find(action => action.quantity)
                    if (sAction) {
                        const totalAction = totalActions.find(x => parseInt(x.id, 10) === parseInt(sAction.id, 10))
                        if (!totalAction) {
                            totalActions.push({...sAction, total: item.quantity, lastId: item.productId})
                        } else {
                            totalAction.total = totalAction.total + item.quantity
                            totalAction.lastId = item.productId
                        }
                    }
                    
                }
            }
            item.total = item.quantity * item.bonus
        })
        // добавляем баллы за количественные акции последнему активному элементу (это все типо на сервере)
        if ((totalActions || []).length > 0) {
            totalActions.forEach(tAction => {
                if(tAction.total >= tAction.quantity) {
                    const sItem = items.find(x => parseInt(x.productId, 10) === parseInt(tAction.lastId, 10))
                    if (sItem) {
                        sItem.total = sItem.total + (Math.floor(tAction.total / tAction.quantity) * tAction.bonus)
                    }
                }
            })
        }
    }
    
    return [...items]
}

function creatBasketActionItem (items, products) {
    let totalActions = []
    if ((items || []).length > 0) {
        items.forEach(item => {
            const product = products.find(x => parseInt(x.productId, 10) === parseInt(item.productId, 10))
            if (product) {
                if ((product.actions || []).length > 0) {
                    const sAction = product.actions.find(action => action.quantity)
                    if (sAction) {
                        const totalAction = totalActions.find(x => parseInt(x.id, 10) === parseInt(sAction.id, 10))
                        if (!totalAction) {
                            totalActions.push({...sAction, total: item.quantity})
                        } else {
                            totalAction.total = totalAction.total + item.quantity
                        }
                    }
                    
                }
            }
        })
    }
    
    return totalActions
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }