<template>
    <f7-page 
      name="actions"
      class="page-actions"
      bg-color="white" 
      ptr 
      :ptr-preloader="false" 
      @ptr:refresh="refrashPage"
      infinite
      :infinite-preloader="allowInfinite"
      @infinite="infinitePage"
      @page:reinit="reinitPage">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-ios="material:menu" icon-md="material:menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title sliding>Текущие акции</f7-nav-title>
            <f7-nav-right>
              <f7-link icon-only href="/cart/">
                <f7-icon f7="cart_fill" class="icon f7-icons">
                  <f7-badge v-if="$store.state.basket.count > 0" color="lightblue">{{ $store.state.basket.count }}</f7-badge>
                </f7-icon>
              </f7-link>
            </f7-nav-right>
        </f7-navbar>
        <!-- Page content-->
        <f7-list v-show="loading" media-list v-for="n in 3" :key="n" class="skeleton-text skeleton-effect-blink">
            <f7-list-item link="#" title="Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor">
                <f7-skeleton-block style="width: 30px; height: 30px;" slot="media"></f7-skeleton-block>
            </f7-list-item>
        </f7-list>
        <f7-list v-show="!loading" media-list class="search-list searchbar-found">
            <f7-list-item v-for="item in items" :key="item.actionId" :link="`/actions/${item.actionId}/`" :title="item.title">
                <f7-icon slot="media" f7="percent"></f7-icon>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>
  export default {
    name: "actions",
    data () {
      return {
        loading: false,
        items: [],
        totalItems: 0,
        order: 'actionId',
        by: 'desc',
        page: 1,
        filter: {},
        searchElement: '',

        allowInfinite: false,
      }
    },
    methods: {
      async getData () {
        try {
          const { items, totalItems } = await this.$store.dispatch('actions/getList', {
            page: this.page, 
            order: this.order, 
            by: this.by, 
            filter: this.filter, 
            search: this.searchElement
          })
          if ((items || []).length > 0) {
            this.items = [...this.items, ...items]
          }
          this.$f7.lazy.create('.page-actions.page-current')
          this.totalItems = totalItems
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.commit('setError', err)
        }
      },
      async infinitePage () {
        if (this.allowInfinite) 
          return false

        if ((this.items || []).length >= this.totalItems)
          return false

        this.allowInfinite = true
        ++this.page
        await this.getData()
        this.allowInfinite = false
      },
      async refrashPage(done) {
        this.loading = true
        done()
        this.clearItems()
        await this.getData()
      },
      async reinitPage () {
        this.loading = true
        this.clearItems()
        await this.getData() 
      },
      clearItems () {
        this.items = []
        this.count = 0
        this.page = 1
      }
    },
    async mounted () {
      this.loading = true
      this.clearItems()
      await this.getData()
    }
  }
</script>

<style scoped>

</style>
