<template>
  <f7-page name="action" class="page-action" bg-color="white">
    <!-- Top Navbar -->
    <f7-navbar :title="title" back-link="Back"></f7-navbar>
    
    <f7-block-title v-show="loading" class="skeleton-text skeleton-effect-blink" medium>Lorem Ipsum is simply dummy text</f7-block-title>
    <f7-block v-show="loading" class="skeleton-text skeleton-effect-blink">
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
    </f7-block>

    <f7-block-title v-show="!loading" medium>{{ title }}</f7-block-title>
    <f7-block v-show="!loading">
        <p>{{ description }}</p>
      <f7-list>
        <f7-list-item title="Начало" :after="start"></f7-list-item>
        <f7-list-item title="Завершение" :after="end"></f7-list-item>
      </f7-list>
      <f7-block-title  v-show="!loading" medium style="font-size: 18px">Статистика акции</f7-block-title>
      <f7-list>
        <f7-list-item title="Зачислено всего" :after="`${totalBonus} Баллов`"></f7-list-item>
        <f7-list-item v-if="actionType === 'quantity'" title="Сумма бонусов за шаг" :after="`${summStep} Баллов`"></f7-list-item>
        <f7-list-item v-if="actionType === 'quantity'" title="Шаг акции (шт)" badge="10"></f7-list-item>
        <f7-list-item v-if="actionType === 'quantity'" title="Набрано (шт)" :badge="`${quantityAvailable}`" badge-color="lightblue"></f7-list-item>
        <f7-list-item v-if="actionType === 'quantity'" title="На рассмотрении (шт)" :badge="`${quantitySubmitted}`" badge-color="yellow"></f7-list-item>
        <f7-list-item v-if="actionType === 'quantity'" title="Необходимо (шт)" :badge="`${quantityRequired}`" badge-color="red"></f7-list-item>
      </f7-list>
    </f7-block>

    <f7-block-title  v-show="!loading && products.length > 0" medium style="font-size: 16px">Товары акции</f7-block-title>
    <f7-list v-show="!loading && products.length > 0" media-list>
      <f7-list-item v-for="item in products" :key="item.productId" :link="`/product/${item.productId}/`" :header="item.title">
        <div slot="after" class="text-color-primary">{{ item.bonus }} Баллов</div>
        <img slot="media" :data-src="item.src" class="lazy lazy-fade-in" width="80" height="60" />
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    name: "action",
    // computed: {
    //   action () {
    //     if ((this.$store.state.actions.actions || []).length > 0 && this.$f7route.params.hasOwnProperty('actionId')) {
    //       let action = this.$store.state.actions.actions.find(x => parseInt(x.actionId, 10) === parseInt(this.$f7route.params.actionId, 10))
    //       if (action) {
    //         return action
    //       }
    //     }
    //     return {}
    //   },
    //   actionProducts () {
    //     if ((this.$store.state.product.products || []).length > 0 && Object.keys(this.action || {}).length > 0 && this.$f7route.params.hasOwnProperty('actionId')) {
    //       return this.$store.state.product.products.filter(item => parseInt(item.actionId, 10) === parseInt(this.$f7route.params.actionId, 10))
    //     }
    //     return []
    //   }
    // },
    data () {
      return {
        loading: false,
        title: '',
        description: '',
        actionType: '',
        start: null,
        end: null,
        totalBonus: 0,
        step: 0,
        summStep: 0,
        quantityAvailable: 0,
        quantitySubmitted: 0,
        quantityRequired: 0,
        products: []
      }
    },
    methods: {
      async getData () {
        try {
          if (!this.$f7route.params.hasOwnProperty('actionId')) {
            throw new Error('data error')
          }
          this.loading = true
          const { title, description, actionType, start, end, totalBonus, step, summStep, quantityAvailable, quantitySubmitted, quantityRequired, products } = await this.$store.dispatch('actions/getData', this.$f7route.params.actionId)
          this.title = title || ''
          this.description = description || ''
          this.actionType = actionType || ''
          this.start = start || null
          this.end = end || null,

          this.totalBonus = totalBonus || 0
          this.step = step || 0
          this.summStep = summStep || 0
          this.quantityAvailable = quantityAvailable || 0
          this.quantitySubmitted = quantitySubmitted || 0
          this.quantityRequired = quantityRequired || 0

          this.products = products || []
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.commit('setError', err)
        }
      }
    },
    async mounted () {
      await this.getData()
      this.$f7.lazy.create('.page-action.page-current')
    }
  }
</script>

<style scoped>

</style>
