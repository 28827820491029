import api from '../js/api.js'

const state = {

}

const getters = {}

const actions = {
  async getStatistics ({ rootState }) {
    try {
      if (rootState.auth.userId === null) {
        new Error('no-auth')
      }

      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          let bonusTotal = 0
          let bonusSuccess = 0
          let bonusSubmitted = 0
          const basket = rootState.base.basket.filter(x => x.orderId !== null)
          if ((basket || []).length > 0) {
            bonusTotal = (basket || []).length === 1 ? basket[0].total : basket.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
          }
          const basketSubmitted = rootState.base.basket.filter(x => x.orderId !== null && x.status !== 'denied' && x.status !== 'success')
          if ((basketSubmitted || []).length > 0) {
            bonusSubmitted = (basketSubmitted || []).length === 1 ? basketSubmitted[0].total : basketSubmitted.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
          }
          const basketSuccess = rootState.base.basket.filter(x => x.orderId !== null && x.status === 'success')
          if ((basketSuccess || []).length > 0) {
            bonusSuccess = (basketSuccess || []).length === 1 ? basketSuccess[0].total : basketSuccess.reduce((sum, item) => (typeof sum === 'number' ? sum : sum.total) + item.total)
          }

          resolve({
            status: '1',
            data: { bonusTotal, bonusSuccess, bonusSubmitted },
            errorCode: '',
            errorMessage: ''
          })
        }, 500)
      }))()

      // const { data, status, errorMessage } = await api.get('/actions/', { page, rows, order, by, search })
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      return data
    } catch (err) {
      throw err
    }
  },

  async getList ({ rootState }, params = {}) {
    try {
      if (rootState.auth.userId === null) {
        new Error('no-auth')
      }

      let { page, rows, order, by, search } = params
      page = page && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1
      rows = rows && !isNaN(parseInt(rows, 10)) ? parseInt(rows, 10) : 10
      search =  search || null
      order = order ? order : 'id'
      by = by ? by : 'desc'
      const { data, status, errorMessage } = await (() => new Promise(resolve => {
        setTimeout(() => {
          const items = []
          const applicationList = [...rootState.base.applicationList]

          // сортируем
          applicationList.sort((a, b) => {
            if ((by !== 'asc' && a[order] > b[order]) || (by === 'asc' && a[order] < b[order])) {
              return 1
            }
            if ((by !== 'asc' && a[order] < b[order]) || (by === 'asc' && a[order] > b[order])) {
              return -1
            }
            return 0
          })

          const start = (page - 1) * rows
          const end = rows * page < applicationList.length ? rows * page : applicationList.length

          for (let i = start; i < end; i++) {
            items.push(applicationList[i])
          }

          resolve({
            status: '1',
            data: {
              items,
              totalItems: (applicationList || []).length
            },
            errorCode: '',
            errorMessage: ''
          })
        }, 500)
      }))()

      // const { data, status, errorMessage } = await api.get('/actions/', { page, rows, order, by, search })
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      return data
    } catch (err) {
      throw err
    }
  },

  async getData ({rootState}, id) {
    try {
      if (!id || isNaN(parseInt(id, 10))) {
        throw new Error('Некорректные данные')
      }
      const { data, status, errorMessage } = await (() => new Promise((resolve, reject) => {
        setTimeout(() => {
          const application = [...rootState.base.applicationList].find(x => parseInt(x.id, 10) === parseInt(id, 10))
          if (!application) {
            reject(new Error('Элемент не найден'))
          }
          let products = rootState.base.basket.filter(x => x.orderId === application.id)
          // сортируем
          products.sort((a, b) => {
            if (a.basketId > b.basketId) return 1
            if (a.basketId < b.basketId) return -1
            return 0
          })


          resolve({
            status: '1',
            data: {...application, products},
            errorCode: '',
            errorMessage: ''
          })
        }, 500)
      }))()

      // const { data, status, errorMessage } = await api.get('/actions/', { page, rows, order, by, search })
      if (!data || !status || String(status) !== '1' || String(errorMessage).length > '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      return data
    } catch (err) {
      throw err
    }
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
