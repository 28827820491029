import api from '../js/api.js'
// import api from '../js/request.js'

const state = {
  accessToken: null,
  accessExp: null,
  refreshToken: null,
  userId: 3,
  userName: 'Иванушков Александр',
  isUserLoggedIn: true,
  partnerCode: 42894,
  userType: 'client'
}

const getters = {}

const actions = {
  setLogaut ({commit}) {
    commit('setLogaut')
  },

  async login ({rootState, commit}, {login, password}) {
    try {
      if (!login) {
        throw new Error('unavailable login')
      }

      if (!password) {
        throw new Error('unavailable password')
      }

      const { data, status, errorMessage } = await api.post('/auth/', {login, password, deviceId: rootState.deviceId})
      if (!data || !status || String(status) === '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      const { accessToken, refreshToken } = data
      // const { result } = await api.post('/auth/login', {login: 'admin', password: 'Al511981'})
      // const { accessToken, refreshToken } = result
      if (!accessToken || !refreshToken) {
        throw new Error('uncorrected token')
      }
      if (accessToken) {
        commit('setAccessToken', accessToken)
      }

      if (refreshToken) {
        commit('setRefreshToken', refreshToken)
      }

    } catch (err) {
      console.log(err)
      throw err
    }
  },
  async refreshtoken ({state, commit}) {
    try {
      const { data, status, errorMessage } = await api.post('/refresh/', {refreshToken: state.refreshToken})
      if (!data || !status || String(status) === '0') {
        throw errorMessage || new Error('uncorrected response')
      }

      const { accessToken, refreshToken } = data
      if (!accessToken || !refreshToken) {
        throw new Error('uncorrected token')
      }
      if (accessToken) {
        commit('setAccessToken', accessToken)
      }

      if (refreshToken) {
        commit('setRefreshToken', refreshToken)
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  }
}

// mutations
const mutations = {
  setAccessToken (state, accessToken) {
    const dat = accessToken.split('.')
    const accessTokenData = JSON.parse(Buffer.from(dat[1], 'base64').toString('utf8'))
    state.accessToken = accessToken
    state.accessExp = parseInt(accessTokenData.exp, 10)
    state.isUserLoggedIn = !!(accessToken)
    state.userId = accessTokenData.userId || null
    state.userName = accessTokenData.userName || null
  },
  setRefreshToken (state, refreshToken) {
    state.refreshToken = refreshToken
  },
  setLogaut (state) {
    state.accessToken = null
    state.accessExp = null
    state.refreshToken = null
    state.userId = null
    state.userName = null
    state.isUserLoggedIn = false
  },
  setLoginTest (state) {
    state.userId = 3
    state.userName = 'Иванушков Александр'
    state.isUserLoggedIn = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
