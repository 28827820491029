<template>
  <f7-page name="products" class="page-products" ptr :ptr-preloader="false" @ptr:refresh="refrashPage"
    infinite
    :infinite-preloader="allowInfinite"
    @infinite="infinitePage"
    bg-color="white"
    @page:reinit="reinitPage">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link icon-ios="material:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>StatusPro</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-product" icon-ios="f7:search" icon-aurora="f7:search" icon-md="material:search"></f7-link>
        <f7-link icon-only href="/cart/">
          <f7-icon f7="cart_fill" class="icon f7-icons">
            <f7-badge v-if="$store.state.basket.count > 0" color="lightblue">{{ $store.state.basket.count }}</f7-badge>
          </f7-icon>
        </f7-link>
      </f7-nav-right>
      <f7-searchbar class="searchbar-product" expandable search-container=".search-list" search-in=".item-header" :disable-button="!$theme.aurora" :custom-search="true" @searchbar:search="searchProductSearch" @searchbar:clear="searchProductClear" @searchbar:disable="searchProductDisable"></f7-searchbar>
        <f7-subnavbar class="subnavbar-product">
          <f7-segmented >

            <f7-link icon-only back @click="barcodeSearchProduct">
              <f7-icon f7="qrcode" class="icon f7-icons">
                <f7-badge v-if="isBarcode" color="lightblue"><f7-icon f7="checkmark" class="icon f7-icons" size="8px"></f7-icon></f7-badge>
              </f7-icon>
            </f7-link>

            <f7-link icon-ios="f7:line_horizontal_3_decrease" icon-md="f7:line_horizontal_3_decrease" back @click="sortProduct"></f7-link>

            <f7-link icon-only back @click="popupFilterProduct = true">
              <f7-icon f7="slider_horizontal_3" class="icon f7-icons">
                <f7-badge v-if="filterProductActive !== 0" color="lightblue">{{ filterProductActive }}</f7-badge>
              </f7-icon>
            </f7-link>

            <f7-link icon-ios="f7:camera_fill" icon-md="f7:camera_fill" back @click="cameraClick"></f7-link>

          </f7-segmented>
        </f7-subnavbar>
    </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
      <div slot="before-inner">
        <f7-row no-gap>
          <f7-col>ДОСТУПНО</f7-col>
          <f7-col>ПРОВЕРЯЕТСЯ</f7-col>
        </f7-row>
      </div>
      <div slot="after-inner">
        <f7-row no-gap>
          <f7-col>{{ $store.state.bonus.availableBonuses }} Баллов</f7-col>
          <f7-col>{{ $store.state.bonus.totalSubmitted }} Баллов</f7-col>
        </f7-row>
      </div>
    </f7-toolbar>
    <!-- Page content-->
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Товары не найдены"></f7-list-item>
    </f7-list>
    <f7-list v-show="!loading && items.length === 0">
      <f7-list-item title="Товары не найдены"></f7-list-item>
    </f7-list>
    <f7-list v-show="loading" media-list class="skeleton-text skeleton-effect-blink">
      <f7-list-item v-for="n in 3" :key="n" link="#" after="100 Lorem" header="Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor">
        <f7-skeleton-block style="width: 80px; height: 60px;" slot="media"></f7-skeleton-block>
      </f7-list-item>
    </f7-list>
    <f7-list v-show="!loading && items.length > 0" media-list class="search-list searchbar-found">
      <f7-list-item v-for="item in items" :key="item.productId" :link="`/product/${item.productId}/`" :header="item.title">
        <div slot="after" class="text-color-primary">{{ item.actions[0].bonus }} Баллов</div>
        <img slot="media" :data-src="item.src" class="lazy lazy-fade-in" width="80" height="60" />
      </f7-list-item>
    </f7-list>

    <f7-popup :opened="popupSortProduct" @popup:close="sortProductClose" @popup:closed="popupSortProduct = false">
      <f7-navbar title="Сортировка товара">
        <f7-nav-right>
          <f7-link popup-close>Закрыть</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-item radio :checked="order == 'title' && by == 'desc'" @change="sortProductChange ('title', 'desc')">
          <div slot="title">По алфавиту</div>
        </f7-list-item>
        <f7-list-item radio :checked="order === 'bonus' && by === 'asc'" @change="sortProductChange ('bonus', 'asc')">
          <div slot="title">Сначала дорогие</div>
        </f7-list-item>
        <f7-list-item radio :checked="order === 'bonus' && by === 'desc'" @change="sortProductChange ('bonus', 'desc')">
          <div slot="title">Сначала недорогие</div>
        </f7-list-item>



        <!-- <f7-list-item radio value="desc" name="name">
          <div slot="title"><f7-icon f7="sort_up_circle_fill" size="20px" color="primary"></f7-icon> по алфавиту</div>
        </f7-list-item>
        <f7-list-item radio value="check_2" name="demo-radio" title="Radio 2"></f7-list-item> -->
      </f7-list>
    </f7-popup>

    <f7-popup :opened="popupFilterProduct" @popup:close="closePopupFilterProduct" @popup:opened="openedPopupFilterProduct">
      <f7-view >
        <f7-page>
          <f7-navbar>
            <f7-nav-left>
              <f7-link icon="icon-back" popup-close></f7-link>
            </f7-nav-left>
            <f7-nav-title sliding>Фильтр товаров</f7-nav-title>
            <f7-nav-right>
              <f7-link icon-only icon-f7="trash" @click="clearFilterProduct"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <!-- Scrollable sheet content -->
          <f7-list v-show="loadingFilterProduct" class="skeleton-text skeleton-effect-blink">
            <f7-list-item v-for="n in 4" :key="n" title="aaaaaaa aaaaaaaa" href="#"></f7-list-item>
          </f7-list>
          <f7-list v-show="!loadingFilterProduct">
            <f7-list-item 
              v-for="property in filterProperty" 
              :key="property.id" 
              :title="property.title" 
              smart-select 
              :smart-select-params="{ searchbar: (property.values || []).length > 10 ? true : false, formatValueText: maskFormatValueText }"
              >
              <!-- <span v-if="filterProductActive" slot="after">After End</span> -->
              <select :name="property.title" :multiple="property.multiple" v-model="filter[property.alias]">
                <option v-if="!property.multiple" :key="0" :value="null" data-display-as=" ">Все эллементы</option>
                <option v-for="prop in property.values" :key="prop.id" :value="prop.id" :disabled="prop.disabled">{{ prop.value }} {{prop.disabled}}</option>
              </select>
            </f7-list-item>
          </f7-list>
          <f7-fab position="center-bottom" slot="fixed" color="lightblue" @click="popupFilterProduct = false">
            <span v-if="productQuantity === 1" slot="text">Показать {{productQuantity}} товар</span>
            <span v-if="productQuantity > 1 && productQuantity < 5" slot="text">Показать {{productQuantity}} товара</span>
            <span v-if="productQuantity === 0 || productQuantity > 4" slot="text">Показать {{productQuantity}} товаров</span>
          </f7-fab>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>

<script>
  import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
  export default {
    name: "products",
    computed: {
      items () {
        return this.$store.state.product.items || []
      }
    },
    data() {
      return {
        loading: false,
        order: 'title',
        by: 'desc',
        page: 1,
        filter: {},
        allowInfinite: false,
        showPreloader: false,

        popupSortProduct: false,
        popupFilterProduct: false,

        loadingFilterProduct: false,
        filterProductActive: false,
        filterProperty: [],
        productQuantity: 0,
        searchProduct: null,
        isBarcode: false
      }
    },
    methods: {
      maskFormatValueText (sel) {
        let filterProductActive = 0
        for (const key of Object.keys(this.filter)) {
          
          if (this.filter[key] !== null) {
            if (typeof this.filter[key] === "string") {
              if (String(this.filter[key]).length !== 0) {
                ++filterProductActive
              }
            } else {
              if (this.filter[key].length !== 0) {
                ++filterProductActive
              }
            }
          }
        }
        this.filterProductActive = filterProductActive
        return sel.length === 0 || (sel.length === 1 && (sel[0] === null || String(sel[0]).trim().length === 0)) ? '' : `${sel.length} выбрано`
      },
      async getData () {
        try {
          this.page = 1
          await this.$store.dispatch('product/productList', {page: this.page, order: this.order, by: this.by, filter: this.filter, search: this.searchProduct})
          this.$f7.lazy.create('.page-products.page-current')
          this.loading = false 
        } catch (err) {
          this.$store.commit('setError', err)
          this.loading = false 
        }
      },
      async refrashPage (done) {
          this.loading = true
          this.isBarcode = false
          done()
          this.$store.commit('product/setProducts', [])
          this.searchProduct = null
          await this.getData() 
      },
      async reinitPage () {
          this.loading = true
          this.isBarcode = false
          this.$store.commit('product/setProducts', [])
          await this.getData() 
      },
      async infinitePage () {
        try {
          if (this.allowInfinite) return
          if ((this.$store.state.product.items || []).length >= this.$store.state.product.count) return
          this.allowInfinite = true
          ++this.page
          await this.$store.dispatch('product/productList', {page: this.page, order: this.order, by: this.by, filter: this.filter, search: this.searchProduct})
          // setTimeout(() => this.$f7.lazy.create('.page-products'), 0)
          this.$f7.lazy.create('.page-products.page-current')
          this.showPreloader = false
          this.allowInfinite = false
          return true
        } catch (err) {
          this.showPreloader = false
          this.allowInfinite = false
          this.$store.commit('setError', err)
        }
      },
      async barcodeSearchProduct () {
        try {console.log('barcodeSearchProduct', this.isBarcode)
          if (this.isBarcode) {
            this.loading = true
            this.isBarcode = false
            return await this.getData()
          }

          let barcode = await (() => new Promise((resolve, reject) => {
            if (Device.cordova && cordova && cordova.plugins && cordova.plugins.barcodeScanner) {
              cordova.plugins.barcodeScanner.scan(
                (result) => {
                  resolve(result)
                },
                (err) => {
                  this.$store.commit('setError', err)
                  resolve(null)
                },{
                  preferFrontCamera : false, // iOS and Android
                  showFlipCameraButton : false, // iOS and Android
                  showTorchButton : true, // iOS and Android
                  torchOn: false, // Android, launch with the torch switched on (if available)
                  saveHistory: false, // Android, save scan history (default false)
                  prompt : "Поместите штрих - код в область сканирования", // Android
                  resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                  // formats : "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
                  orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                  disableAnimations : true, // iOS
                  disableSuccessBeep: false // iOS and Android
                })
            } else {
              resolve(null)
            }
          }))()

          if (barcode === null) {
            this.$store.commit('setError', 'Устройство распознавания не найдено')
            return false
          } 
          this.isBarcode = true
          this.searchProduct = null
          this.page = 1
          this.loading = true
          this.clearObFilter()
          this.$store.commit('product/setProducts', [])
          await this.$store.dispatch('product/productList', { barcode: barcode.text })
          // setTimeout(() => this.$f7.lazy.create('.page-products'), 0)
          this.$f7.lazy.create('.page-products.page-current')
          // проверяем
          if (this.items.length === 1) {
            this.$f7router.navigate(`/product/${this.items[0].productId}/`)
            this.loading = false
            return true
          }

          this.loading = false
        } catch (err) {
          this.$store.commit('setError', `${err}`)
        }
      },
      sortProduct () {
        this.popupSortProduct = true
      },
      async openedPopupFilterProduct () {
        this.loadingFilterProduct = true
        
        const { items, productQuantity }= await this.$store.dispatch('product/getFilter', this.filter)
        this.filterProperty = [...items]
        this.productQuantity = productQuantity
        if ((this.filterProperty || []).length > 0) {
          this.filterProperty.forEach(prop => {
            if (!this.filter.hasOwnProperty(prop.alias)) {
              this.filter[prop.alias] = Boolean(prop.multiple) === true ? [] : null
            }
          })
        }
        this.loadingFilterProduct = false
        setTimeout(() => {
          let elements = document.querySelectorAll('.smart-select')
          elements.forEach(element => {
            const smartSelect = this.$f7.smartSelect.get(element)
            smartSelect.on('closed', async () => {
              await this.updateFilterProduct()
            })
          })
        }, 0)
      },
      async sortProductChange (order, by) {
        this.order = order 
        this.by = by
      },

      async sortProductClose () {
        this.loading = true
        await this.getData()
      },
      async closePopupFilterProduct () {
          let elements = document.querySelectorAll('.smart-select')
          elements.forEach(element => {
            const smartSelect = this.$f7.smartSelect.get(element)
            smartSelect.off('closed')
          })

        this.popupFilterProduct = false
        this.loading = true
        await this.getData()
      },

      clearObFilter () {
        const filter = {}
        if ((this.filterProperty || []).length > 0) {
          this.filterProperty.forEach(prop => {
            filter[prop.alias] = Boolean(prop.multiple) === true ? [] : null
          })
        }
        this.filter = {...filter}
        this.filterProductActive = 0
      },
      async clearFilterProduct () {
        this.loadingFilterProduct = true
        this.clearObFilter()
        await this.updateFilterProduct()
        let elements = document.querySelectorAll('.smart-select .item-after')
        for (let elem of elements) {
          elem.innerHTML = ''
        }
        this.loadingFilterProduct = false
      }, 
      async updateFilterProduct () {
        try {
          const { items, productQuantity }= await this.$store.dispatch('product/getFilter', this.filter)
          this.productQuantity = productQuantity
          this.filterProperty = [...items]
        } catch (err) {
          await this.closedPopupFilterProduct()
        }

      },
      async searchProductSearch (searchbar, query, previousQuery) {
        console.log('searchProductSearch', searchbar, query, previousQuery)
        this.searchProduct = query
        this.loading = true
        await this.getData()
      },
      async searchProductClear (searchbar, previousQuery) {
        console.log('searchProductClear', searchbar, previousQuery)
        this.searchProduct = null
        this.loading = true
        await this.getData()
      },
      async searchProductDisable (searchbar) {
        console.log('searchProductDisable', searchbar)
        if (this.searchProduct !== null) {
          this.searchProduct = null
          this.loading = true
          await this.getData()
        }
        
      },
      async cameraClick () {
        if (!Device.cordova) {
          return false
        }
        // const imageURI = await (() => new Promise(resolve => { 
        //   navigator.camera.getPicture((imageURI) => {
        //     resolve(imageURI)
        //   }, (err) => {
        //     alert('Failed because: ' + err)
        //   },{ 
        //     quality: 50, 
        //     destinationType: 
        //     Camera.DestinationType.FILE_URI 
        //   })
        // }))()

        const imageURI = await (() => new Promise(resolve => { 
          navigator.camera.getPicture((imageURI) => {
            resolve(imageURI)
          }, (err) => {
            alert('Failed because: ' + err)
          },{ 
            quality: 50, 
            destinationType: 
            Camera.DestinationType.FILE_URI,
            sourceType: Camera.PictureSourceType.PHOTOLIBRARY
          })
        }))()



        alert(imageURI)
      }
    },
    async mounted() {
      console.log('mountedmountedmounted---')
        this.loading = true
        this.$store.commit('product/setProducts', [])
        this.filterProductActive = 0
        await this.getData()
    }
  }
</script>

<style scoped>
  .toolbar {text-align: center}
  .subnavbar-product a.icon-only {width: 33%;}
  .fab-extended {width: 80%;}
</style>
